import http from "@/http";

class PropertyDashService {
    constructor() {
        this.baseUrlProperty = 'platform/property';
        this.baseUrlData = 'platform/data';
    }

    listProperties() {
        return http.get(`${this.baseUrlProperty}?page_size=100`)
            .then(({ data }) => {
                return data
            })
    }

    getDashHeaderData() {
        return http.get(`${this.baseUrlData}/dash`)
            .then(({ data }) => {
                return data
            })
    }
}

export default new PropertyDashService()