<template>
  <div>
    <v-expansion-panels focusable :value="expanded">
      <v-expansion-panel>
        <v-expansion-panel-header
          >{{ title }} ({{ images.length }})</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row>
            <v-container>
              <image-upload
                :selectedParent="selectedParent"
                :endpoint="endpoint"
                @allFilesUploaded="reloadGalleryImages()"
                @displaySavePropertyDialog="saveParentDialogToggle = true"
              ></image-upload>
            </v-container>
          </v-row>
          <!-- Fix for realoading image -->
          <v-row :key="imageReload">
            <v-col
              v-for="image in images"
              :key="image.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-hover v-slot="{ hover }">
                <v-img
                  :src="image.media_link"
                  :lazy-src="image.media_link"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        transition-fast-in-fast-out
                        grey
                        darken-2
                        v-card--reveal
                        text-h2
                        white--text
                      "
                      style="height: 100%"
                    >
                      <v-spacer></v-spacer>
                      <!-- <v-btn fab dark large color="blue" @click="&quot;&quot;;">
                        <v-icon dark> visibility </v-icon>
                      </v-btn> -->
                      <!-- <v-spacer></v-spacer> -->
                      <v-btn
                        fab
                        dark
                        large
                        color="red"
                        @click="deleteImage(image.id)"
                      >
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-hover>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- SAVE PROPERTY BEFORE ADDING IMAGES DIALOG -->
    <v-dialog v-model="saveParentDialogToggle" persistent max-width="450">
      <v-card>
        <v-card-title class="text-h5">Save Property</v-card-title>
        <v-card-text
          >You must save the current Property before adding pictures or
          videos.<br />
          <strong>Do you wish to save the current property now? </strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="saveParentDialogToggle = false"
          >
            No, Keep editing
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="(saveParentDialogToggle = false), saveParent()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE DIALOG -->
    <v-row justify="center">
      <v-dialog v-model="deleteToggle" persistent max-width="450">
        <v-card>
          <v-card-title class="text-h5"> Delete Image </v-card-title>
          <v-card-text
            >Are you sure you want to delete the current image? <br />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteToggle = false">
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="(deleteToggle = false), deleteImageConfirmed()"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ImageUpload from "@/components/media/ImageUpload";
import MediaService from "@/services/mediaService";

export default {
  mounted() {
    if (this.selectedParent.id) {
      this.loadAllMedia(this.selectedParent.id)
    }
  },
  components: {
    ImageUpload,
  },
  props: {
    title: null,
    endpoint: null,
    expanded: {
      default: 1,
    },
    selectedParent: null,
    withUpload: {
      default: false,
    },
  },
  data() {
    return {
      deleteToggle: false,
      clickedImageId: null,
      images: [],
      saveParentDialogToggle: false,
      imageReload: 0
    };
  },
  methods: {
    loadAllMedia(id) {
      MediaService.listMedia(this.endpoint, id)
        .then(
          (response) => {
            this.images = response.data.filter((media) => {
              return media.type.includes("image");
            });

            this.images.forEach((image) => {
              MediaService.getProtectedImageLink(this.endpoint,
                image.id,
                image.extension
              )
                .then(
                  (response) => {
                    image.media_link = response.data;
                  },
                  (error) => {
                    console.log(error);
                  }
                )
                .finally(() => {
                  this.$store.dispatch("subtractLoading");
                  this.imageReload++;
                });
            });
          },
          (error) => {
            this.$store.dispatch("openSnackbar", {
              message: "Error: Please, contact support: " + error,
            });
          }
        )
        .finally(() => {
          this.$store.dispatch("subtractLoading");
        });
    },
    deleteImage(imageIdToDelete) {
      this.deleteToggle = true;
      this.clickedImageId = imageIdToDelete;
    },
    deleteImageConfirmed() {
      MediaService.deleteMedia(this.endpoint, this.clickedImageId)
        .then(
          () => {
            //this.loadAllMedia(this.selectedParent.id);
            this.removeMediaFromList();
          },
          (error) => {
            this.$store.dispatch("openSnackbar", {
              message: "Error: " + error.response.data.message,
            });
          }
        )
        .finally(() => {
          this.$store.dispatch("subtractLoading");
        });
    },
    removeMediaFromList() {
      this.images = this.images.filter((image) => {
        return image.id !== this.clickedImageId
      });
    },
    reloadGalleryImages() {
      this.loadAllMedia(this.selectedParent.id)
    },
    saveParent() {
      this.$emit("saveParent", false)
    }
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
</style>