<template>
  <div>
    <v-expansion-panels focusable :value="false">
      <v-expansion-panel>
        <v-expansion-panel-header
          >Management Groups ({{this.savedPropertyManagerGroups.length}})</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-container>
            <v-layout>
              <v-flex sm12 md12>
                <v-select
                  return-object
                  class="pr-2"
                  outlined
                  prepend-inner-icon="list"
                  label="Management Group:"
                  v-model="selectedManagementGroup"
                  :items="managementGroupSelectList"
                  item-text="label"
                  item-value="id"
                  :append-outer-icon="'person_add_alt'"
                  @click:append-outer="addGroup"
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex sm12 md12>
                <v-data-table
                  :headers="groupsHeaders"
                  :items="addedManagementGroups"
                  item-key="id"
                  hide-default-footer
                  :items-per-page="100"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon class="mr-2" @click="removeGroup(item)"
                      >mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- DIALOG TO SAVE PROPERTY BEFORE ADDING NEW DETAIL FOR NEW PROPERTY -->
    <v-dialog v-model="savePropertyDialogToggle" persistent max-width="450">
      <v-card>
        <v-card-title class="text-h5">Save Property</v-card-title>
        <v-card-text
          >You must save the current Property before adding a new manager.<br />
          <strong>Do you wish to save the current property now? </strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(savePropertyDialogToggle = false), resetCurrentDetail()"
          >
            No, Keep editing
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="(savePropertyDialogToggle = false), saveCurrentProperty()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ManagementGroupService from "@/services/managementGroupService";
import PropertyManagerService from "@/services/propertyManagerService";

export default {
  components: {},
  props: {
    selectedProperty: null,
    idOwnerCompany: null
  },

  mounted() {
    // this.listManagementGroups();
  },

  watch: {
    selectedProperty: {
      immediate: true,
      handler(newValue) {
        if(newValue.id){
          this.loadGroupAndUsers(newValue.id);
          //this.listManagementGroups(newValue.id_company);
        }
      },
    },
    idOwnerCompany: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.listManagementGroups(newValue);
        }
      },
    }
  },
  data() {
    return {
      savePropertyDialogToggle: false,
      selectedManagementGroup: null,
      addedManagementGroups: [],
      savedPropertyManagerGroups: [],
      managementGroupSelectList: [],
      groupsHeaders: [
        { text: "Management groups", value: "label" },
        { text: "", align: "end", value: "actions" },
      ],
      selectedGroup: {
        id: "",
        label: "",
        max_members: 10,
      },
      valid: true,
      rules: {
        required: (value) => !!value || "Required field",
      },
      propertyRules: [
        (v) =>
          !!v ||
          "Property is required. Please click on the button below and select a property.",
      ],
    };
  },

  methods: {
    async addGroup() {
      if (this.selectedManagementGroup) {
        if (!this.selectedManagementGroup.id) {
          this.$store.dispatch("openSnackbar", {
            message: "Please, select a management group.",
          });
        } else {
          var isGroupAlreadyAdded = this.addedManagementGroups.some(group => {
            if (group.id === this.selectedManagementGroup.id) {
              return true
            }
            return false
          })
          
          if (!isGroupAlreadyAdded) {
            this.addedManagementGroups.push(this.selectedManagementGroup)
            this.$emit("updateManagementGroup", this.addedManagementGroups);
          } else {
            this.$store.dispatch("openSnackbar", {
              message: "Group already added.",
            });
          }
        }
      }
    },
    async removeGroup(item) {
      if (item && item.id) {
        const indexOfObject = this.addedManagementGroups.findIndex(object => {
          return object.id === item.id;
        });
        this.addedManagementGroups.splice(indexOfObject, 1);
        this.$emit("updateManagementGroup", this.addedManagementGroups);
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async listManagementGroups(companyId) {
      let result = await ManagementGroupService.listGroupsFromCompany(
        this.$store.dispatch, companyId
      );
      if (result.data !== null) {
        this.managementGroupSelectList = [];
        this.managementGroupSelectList.push({
          id: null,
          label: "No group selected",
        });
        this.managementGroupSelectList = this.managementGroupSelectList.concat(
          result.data
        );
      }
    },
    async loadGroupAndUsers(id_property) {
      let result = await PropertyManagerService.loadUsersAndGroupsByPropertyId(
        this.$store.dispatch,
        id_property
      );

      if (result.data !== null) {
        this.savedPropertyManagerGroups = result.data;
        this.addedManagementGroups = []
        this.savedPropertyManagerGroups.forEach(propertyManager => {
          this.addedManagementGroups.push({'id' : propertyManager.id_group, 'label' : propertyManager.label})
        })
        this.$emit("updateManagementGroup", this.addedManagementGroups);
      }
    },
    saveCurrentProperty() {
      this.$emit("saveCurrentProperty", false);
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
