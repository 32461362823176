<template>
  <div class="player">
    <video-player
      ref="videoPlayer"
      class="vjs-custom-skin"
      :options="playerOptions"
      @play="onPlayerPlay($event)"
      @ready="onPlayerReady($event)"
    />
  </div>
</template>

<script>
import VideoPlayer from "@/components/media/VideoPlayer.vue";
// import Adsense from '@/components/xui-adsense/InArticleAdsense.vue'
export default {
  name: "home",
  components: {
    VideoPlayer,
    // Adsense,
  },
  props: {
    src: null,
  },
  data() {
    return {
      playerOptions: {
        // autoplay: false,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // poster: 'https://thumbs.dreamstime.com/b/logo-design-video-83343091.jpg',

        sources: [
          {
            withCredentials: false,
            type: "application/x-mpegURL",
            src: this.src
          },
        ],
      },
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    onPlayerPlay(player) {
      console.log(player);
    },
    onPlayerReady(player) {
      console.log(player);
      // this.player.play()
    },
    playVideo: function (source) {
      const video = {
        withCredentials: false,
        type: "application/x-mpegurl",
        src: source + "#t=0.1",
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      this.player.play();
    },
    switchPlayer: function () {
      // this.playVideo(this.src)
    },
  },
  mounted() {
    // const src = 'https://testsalvedomi-usea.streaming.media.azure.net/19fd9862-52a9-4c8f-bf3d-8f68aea54608/67356127253__4d33198c-cf6d-4829-.ism/manifest(format=m3u8-aapl).m3u8'
    // this.playVideo(src)
  },
};
</script>

<style scoped>
.player {
  /* position: relative !important; */
  /* width: 50%; */
  height: 250px;
  /* margin-top: 10px; */
}
.vjs-custom-skin {
  height: 100% !important;
}
.vjs-custom-skin /deep/ .video-js {
  width: 100% !important;
  height: 100%;
}
</style>