import http from "@/http";

class DetailService {
    constructor() {
        this.baseUrl = 'platform/detail'
        this.pageSize = 50
    }

    listDetailByProperty(id_property) {
        return http.get(`${this.baseUrl}?id_property=${id_property}&page=1&page_size=${this.pageSize}`)
            .then(({ data }) => {
                return data
            })
    }

    deleteDetail(id) {
        return http.delete(`${this.baseUrl}/${id}`, { data: { description: "deleted" } })
            .then(({ data }) => {
                return data
            })
    }

    addDetail(detail) {
        return http.post(`${this.baseUrl}`, detail)
            .then(({ data }) => {
                return data
            })
    }

    editDetail(id, detail) {
        return http.put(`${this.baseUrl}/${id}`, detail)
            .then(({ data }) => {
                return data
            })
    }
}

export default new DetailService()