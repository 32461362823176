<template>
  <v-form v-model="valid">
    <div class="text-xs-center signup-validation-container">
      <v-layout pa-2>
        <v-flex>
          <v-layout justify-center row class="signup-validation-header mt-6">
            <v-img contain src="@/assets/icons/signup-confirmation.png" />
          </v-layout>

          <v-layout align-center justify-center row>
            <v-flex lg12 class="signup-validation-title">
              Active your account
            </v-flex>
          </v-layout>

          <v-layout align-center justify-center row>
            <v-flex lg12 class="signup-validation-subtitle">
              An account registration request has been sent to
              <strong>{{ validationEmail }}</strong
              >, to activate your account check your email and paste your code.
              <br />
              <br />
              Didn't receive an email?<strong
                class="resend-code"
                @click="resendValidationCode"
                >Send me the code again</strong
              >
            </v-flex>
          </v-layout>

          <v-layout align-center justify-center row>
            <v-flex lg12>
              <v-text-field
                outlined
                type="tel"
                label="Validation code"
                color="primary"
                maxlength="6"
                v-model="validationCode"
                prepend-inner-icon="vpn_key"
                :rules="[rules.required, rules.min]"
              />
            </v-flex>
          </v-layout>

          <v-layout justify-center row>
            <v-flex lg12>
              <v-btn
                @click="validateAccount"
                color="primary"
                :disabled="!valid"
                class="request-btn"
                >Validate</v-btn
              >
            </v-flex>
          </v-layout>

          <v-layout justify-center row class="mt-4"
            ><strong class="back-to-login-link" @click="goToLogin"
              >Back to Login</strong
            ></v-layout
          >
        </v-flex>
      </v-layout>
    </div>
  </v-form>
</template>

<script>
import router from "@/router/index.js";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["validationEmail", "validationUserId"]),
  },
  methods: {
    goToLogin() {
      router.push(`/login`);
    },
    resendValidationCode() {
      this.$store.dispatch("resendValidationCode", {
        id_user: this.validationUserId,
      });
    },
    async validateAccount() {
      let response = await this.$store.dispatch("validateAccount", {
        id_user: this.validationUserId,
        activation_code: this.validationCode,
      });

      if (response) {
        router.push(`/login`);
      }
    },
  },
  data() {
    return {
      valid: null,
      loading: false,
      validationCode: null,
      rules: {
        required: (value) => !!value || "Required field",
        min: (v) => (v && v.length >= 6) || "Minimum 6 characters",
      },
    };
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/signup-validation/signup-validation.styl';
</style>

