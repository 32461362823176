
export const notificationList = (state) => {
    return state.notificationList;
  };

export const isNotificationServiceConnected = (state) => {
    return state.isNotificationServiceConnected;
};

export const notificationServiceConnection = (state) => {
  return state.notificationServiceConnection;
};
