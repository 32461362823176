<template>
  <div>
    <!-- TODO: DO SOME KIND OF ERROR ALERT, IF ANY OF THE DETAIL REQUESTS FAIL -->
    <v-container style="height: 100px" v-if="idProperty && loadingDetails">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="text-subtitle-1 text-center" cols="12">
          Loading property details...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="blue accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <!-- EXPANSION PANEL -->
    <v-expansion-panels accordion focusable :key="reloadGroups" v-else>
      <v-expansion-panel
        v-for="detailGroup in detailGroups"
        :key="detailGroup.id"
      >
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon class="icon">touch_app</v-icon>
          </template>
          <div>
            <v-icon color="primary">{{
              detailGroupOptions[detailGroup.label]
                ? detailGroupOptions[detailGroup.label].icon
                : "house"
            }}</v-icon>
            <span class="ml-3">
              <v-badge
                offset-x="-5"
                offset-y="10"
                v-if="detailGroup.details && detailGroup.details.length > 0"
                color="green"
                :content="detailGroup.details.length"
                >{{ detailGroup.label }}</v-badge
              >
              <v-badge
                offset-x="-5"
                offset-y="10"
                v-else
                color="grey"
                content="0"
                >{{ detailGroup.label }}</v-badge
              >
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="d-flex justify-end mb-6">
            <v-btn
              class="mt-2"
              small
              depressed
              color="primary"
              @click="newDetail(detailGroup)"
              >New Detail</v-btn
            >
          </div>
          <div v-for="detail in detailGroup.details" :key="detail.id">
            <v-card class="mx-auto" max-width="100%" outlined>
              <v-list-item three-line>
                <v-list-item-avatar tile size="80">
                  <img
                    v-if="detail.media_link"
                    :src="detail.media_link"
                    style="width: 100%"
                    alt=""
                  />
                  <!-- <span v-else class=""> hide_image </span> -->
                  <span v-else>
                    <v-icon size="60" color="grey ligthen-2">
                      image_not_supported
                    </v-icon>
                  </span>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ detail.label }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-badge
                  class="ml-2"
                  :color="detail.total_images ? 'green' : 'grey'"
                  :content="String(detail.total_images)"
                >
                  <v-icon size="19" color="blue darken-2">
                    photo_camera
                  </v-icon>
                </v-badge>

                <v-badge
                  class="ml-5 mt-1"
                  offset-x="-1"
                  offset-y="7"
                  :color="detail.total_videos > 0 ? 'green' : 'grey'"
                  :content="String(detail.total_videos)"
                >
                  <v-icon size="24" color="blue darken-2"> videocam </v-icon>
                </v-badge>

                <v-spacer></v-spacer>
                <div>
                  <v-icon
                    @click="editDetail(detailGroup, detail)"
                    medium
                    color="blue darken-2"
                  >
                    mdi-pencil
                  </v-icon>
                </div>
              </v-card-actions>
            </v-card>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- DETAIL FORM COMPONENT -->
    <detail-form
      :showForm="this.showForm"
      :idProperty="idProperty"
      :detailGroup="currentDetailGroup"
      :detail="currentDetail"
      @closed="closedDetail"
      @saved="savedDetail"
    ></detail-form>
    <!-- DIALOG TO SAVE PROPERTY BEFORE ADDING NEW DETAIL FOR NEW PROPERTY -->
    <v-dialog v-model="savePropertyDialogToggle" persistent max-width="450">
      <v-card>
        <v-card-title class="text-h5">Save Property</v-card-title>
        <v-card-text
          >You must save the current Property before adding a new Detail.<br />
          <strong>Do you wish to save the current property now? </strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(savePropertyDialogToggle = false), resetCurrentDetail()"
          >
            No, Keep editing
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="(savePropertyDialogToggle = false), saveCurrentProperty()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DetailGroupService from "@/services/detailGroupService";
import DetailService from "@/services/detailService";
import MediaService from "@/services/mediaService";
import DetailForm from "@/components/detail/DetailForm";

export default {
  components: {
    DetailForm,
  },
  created() {
    this.resetCurrentDetail();
  },
  props: {
    idProperty: null,
  },
  watch: {
    idProperty: {
      immediate: true,
      handler() {
        this.listDetailGroup();
      },
    },
  },
  data() {
    const detailGroupOptions = {
      "The property": {
        icon: "house",
      },
      "Kid stuff": {
        icon: "child_care",
      },
      "Cool stuff": {
        icon: "tips_and_updates",
      },
      "Local tips": {
        icon: "pin_drop",
      },
      General: {
        icon: "travel_explore",
      },
      Connectivity: {
        icon: "wifi",
      },
    };

    return {
      detailGroupOptions: detailGroupOptions,
      detailGroups: [],
      reloadGroups: 0,
      loadingDetails: false,
      showForm: false,
      currentDetailGroup: null,
      currentDetail: null,
      savePropertyDialogToggle: false,
      defaultDetail: {
        id: "",
        id_property: null,
        id_detail_group: null,
        label: "",
        description: "",
        sensitive_content: false,
        same_property_address: true,
      },
    };
  },
  methods: {
    newDetail(detailGroup) {
      if (this.idProperty) {
        this.showForm = true;
      } else {
        this.savePropertyDialogToggle = true;
      }
      this.currentDetailGroup = detailGroup;
    },
    editDetail(detailGroup, detail) {
      this.showForm = true;
      this.currentDetailGroup = detailGroup;
      this.currentDetail = Object.assign({}, detail);
    },
    closedDetail() {
      this.showForm = false;
      this.resetCurrentDetail();
    },
    savedDetail() {
      this.showForm = false;
      this.resetCurrentDetail();
      this.listDetailGroup();
    },
    resetCurrentDetail() {
      this.currentDetail = Object.assign({}, this.defaultDetail);
    },
    saveCurrentProperty() {
      this.$emit("saveCurrentProperty", false);
    },
    listDetailGroup() {
      this.loadingDetails = true;
      DetailGroupService.listDetailGroup()
        .then(
          (response) => {
            this.detailGroups = response.data;
            if (this.idProperty) {
              DetailService.listDetailByProperty(this.idProperty)
                .then(
                  (response) => {
                    var details = response.data;
                    if (details.length == 0) {
                      this.loadingDetails = false;
                    }
                    this.detailGroups.forEach((detailGroup) => {
                      detailGroup.details = [];
                      details.forEach((detail) => {
                        if (detailGroup.id === detail.id_detail_group) {
                          // this.listDetailMediaByDetail(detail)
                          this.loadMainMedia(detail);
                          // console.log(detail.media_link)
                          detailGroup.details.push(detail);
                        }
                      });
                    });
                  },
                  () => {
                    this.loadingDetails = false;
                    console.log("ERRO");
                  }
                )
                .finally(() => {
                  this.reloadGroups += 1;
                  this.loadingDetails = false;
                  this.$store.dispatch("subtractLoading");
                });
            }
          },
          () => {
            this.loadingDetails = false;
          }
        )
        .finally(() => {
          this.$store.dispatch("subtractLoading");
          this.reloadGroups += 1;
        });
    },

    loadMainMedia(detail) {
      if (detail.main_id_detail_media) {
        MediaService.getProtectedImageLink(
          "detail",
          detail.main_id_detail_media,
          detail.main_detail_media_extension
        )
          .then(
            (response) => {
              console.log("MAIN_MEDIA: ");
              // console.log(response.data);
              detail.media_link = response.data;
              // image.media_link = response.data;
            },
            (error) => {
              console.log(error);
            }
          )
          .finally(() => {
            this.$store.dispatch("subtractLoading");
            this.reloadGroups += 1;
          });
      }
    },

    // TODO: ADD TO A NEW COMPONENT
    listDetailMediaByDetail(detail) {
      // console.log(detail)
      // var media = []
      MediaService.listMedia("detail", detail.id)
        .then(
          (response) => {
            detail.images = response.data.filter((media) => {
              return media.type.includes("image");
            });
            detail.videos = response.data.filter((media) => {
              return media.type === "application/x-mpegURL";
            });
          },
          () => {}
        )
        .finally(() => {
          this.reloadGroups += 1;
          this.loadingDetails = false;
          this.$store.dispatch("subtractLoading");
        });
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
