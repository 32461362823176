import http from "@/http";
import router from "@/router/index.js";
import { parseJwt } from "../../../utils/JWTDecoder";
import ImageService from "@/services/imageService";

export const login = ({ commit, dispatch }, params) => {
  commit("SET_USER_EMAIL", null);
  localStorage.removeItem("currentUserEmail");
  http
    .post("auth/login", params)
    .then(function (response) {
      if (response.status === 200) {
        commit("SET_USER_EMAIL", params.email);
        localStorage.setItem("currentUserEmail", JSON.stringify(params.email));
        dispatch("subtractLoading");
        commit("SET_COMPANIES", []);
        let res = response.data.data;

        let decoded = parseJwt(res.token);
        // console.log('------->>>>>');
        // console.log(decoded);

        if (decoded && !decoded.verified) {
          dispatch("openSnackbar", {
            message: `Welcome back ${decoded.user_name}, please activate your account before login`,
            color: "success",
          });
          commit("SET_VALIDATION_EMAIL", decoded.email);
          commit("SET_VALIDATION_USER_ID", decoded.user_id);
          router.push("/signup/validation");
        }
        if (decoded && decoded.verified && decoded.companies) {
          commit("SET_COMPANIES", res.companies);
          commit("SET_TEMP_TOKEN", res.token);
          router.push("/login/company-selection");
        } else {

          // console.log('------->>>>>');
          // console.log(decoded);

          let premium_access = decoded.license_type != "FREE_ACCOUNT" ? true : false;
          if (decoded.extra_licenses) {
            decoded.extra_licenses.forEach((license) => {
              if (license.license_type != "FREE_ACCOUNT") {
                premium_access = true;
              }
            })
          }

          if (!premium_access) {
            dispatch("openSnackbar", {
              message: `Contact our support to activate your license on SalveDomi Web Features.`,
              color: "error",
            });
            return;
          }

          dispatch("openSnackbar", {
            message: `Welcome back ${decoded.user_name}`,
            color: "success",
          });

          commit("SET_USER_TOKEN", res.token);
          commit("SET_USER_NAME", decoded.user_name);
          commit("SET_IS_MAIN_ADMIN", decoded.main_admin && decoded.license_type != 'FREE_ACCOUNT' ? true : false);
          commit("SET_COMPANY_NAME", decoded.company_name);
          commit("SET_USER_ID", decoded.user_id);
          commit("SET_PRF_IMG", decoded.prfimg);
          localStorage.setItem("currentUserToken", JSON.stringify(res.token));
          localStorage.setItem("currentUserName", JSON.stringify(decoded.user_name));
          localStorage.setItem("currentIsMainAdmin", JSON.stringify(decoded.main_admin && decoded.license_type != 'FREE_ACCOUNT' ? true : false));
          localStorage.setItem("currentCompanyId", JSON.stringify(decoded.company_id));
          localStorage.setItem("currentCompanyName", JSON.stringify(decoded.company_name));
          localStorage.setItem("currentUserId", JSON.stringify(decoded.user_id));
          localStorage.setItem("currentPrfImg", JSON.stringify(decoded.prfimg));
          // dispatch("loadProfileImage");

          if (!decoded.prfimg) {
            // const profileImageSource = "@/assets/icons/forgot-icon.png";
            // commit("SET_PRF_IMG_FILE", profileImageSource);
            //   localStorage.setItem("currentPrfImgFile", JSON.stringify(base64Image));
            router.push("/");
          } else {
            ImageService.requestUserProfileImage(decoded.user_id, decoded.prfimg).then((base64Image) => {
              commit("SET_PRF_IMG_FILE", base64Image);
              localStorage.setItem("currentPrfImgFile", JSON.stringify(base64Image));
              router.push("/");
            }).finally(() => {
              dispatch("subtractLoading");
            });
          }

        }
      }
    })
    .catch(function (err) {
      console.error("Failed loging in", err);
      let { message } = err.response.data;
      console.log("error", message);
      dispatch("openSnackbar", {
        message: message,
      });
      dispatch("subtractLoading");
      throw err;
    });
};

export const loadProfileImage = ({ commit }) => {
  const currentUserId = JSON.parse(localStorage.getItem("currentUserId"));
  const currentPrfImg = JSON.parse(localStorage.getItem("currentPrfImg"));
  if (!currentPrfImg) {
    console.log("this user hasn't profile image registered");
    return;
  }

  ImageService.requestUserProfileImage(currentUserId, currentPrfImg).then((base64Image) => {
    commit("SET_PRF_IMG_FILE", base64Image);
    localStorage.setItem("currentPrfImgFile", JSON.stringify(base64Image));
  });
};

export const forgotPassword = ({ dispatch }, params) => {
  return new Promise((resolve, reject) => {
    http
      .post("auth/account/change-password-request", params)
      .then(function (response) {
        if (response.status === 200) {
          dispatch("subtractLoading");
          let { message } = response.data;
          dispatch("openSnackbar", {
            message: message,
            color: "success",
          });
        }
        resolve(response.data);
      })
      .catch(function (err) {
        let { message } = err.response.data;
        dispatch("openSnackbar", {
          message: message,
        });
        dispatch("subtractLoading");
        reject(err);
      });
  });
};

export const companyLogin = ({ dispatch, commit }, params) => {
  return new Promise((resolve, reject) => {
    http
      .get(`auth/login/company/${params.id}`, {
        headers: { Authorization: `Bearer ${params.token}` },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch("subtractLoading");
          // let { token } = response.data.data;
          // commit("SET_USER_TOKEN", token);
          // router.push("/");
          let res = response.data.data;

          let decoded = parseJwt(res.token);

          // console.log('--- company ---->>>>>');
          // console.log(decoded.prf);

          let premium_access = decoded.license_type != "FREE_ACCOUNT" ? true : false;

          if (decoded.extra_licenses) {
            decoded.extra_licenses.forEach((license) => {
              if (license.license_type != "FREE_ACCOUNT") {
                premium_access = true;
              }
            })
          }

          if (!premium_access) {
            dispatch("openSnackbar", {
              message: `Contact our support to activate your license on SalveDomi Web Features.`,
              color: "error",
            });

            router.push("/login");
            return;
          }

          //DUPLICATED CODE LOGIN
          dispatch("openSnackbar", {
            message: `Welcome back ${decoded.user_name}`,
            color: "success",
          });
          commit("SET_USER_TOKEN", res.token);
          commit("SET_USER_NAME", decoded.user_name);
          commit("SET_COMPANY_NAME", decoded.company_name);
          commit("SET_IS_MAIN_ADMIN", decoded.main_admin && decoded.license_type != 'FREE_ACCOUNT' ? true : false);
          commit("SET_USER_ID", decoded.user_id);
          commit("SET_PRF_IMG", decoded.prfimg);
          localStorage.setItem("currentUserToken", JSON.stringify(res.token));
          localStorage.setItem("currentUserName", JSON.stringify(decoded.user_name));
          localStorage.setItem("currentIsMainAdmin", JSON.stringify(decoded.main_admin && decoded.license_type != 'FREE_ACCOUNT' ? true : false));
          localStorage.setItem("currentCompanyId", JSON.stringify(decoded.company_id));
          localStorage.setItem("currentCompanyName", JSON.stringify(decoded.company_name));
          localStorage.setItem("currentUserId", JSON.stringify(decoded.user_id));
          localStorage.setItem("currentPrfImg", JSON.stringify(decoded.prfimg));
          // localStorage.setItem("currentUserEmail", JSON.stringify(params.email));
          // dispatch("loadProfileImage");

          if (!decoded.prfimg) {
            // const profileImageSource = "@/assets/icons/forgot-icon.png";
            // commit("SET_PRF_IMG_FILE", profileImageSource);
            //   localStorage.setItem("currentPrfImgFile", JSON.stringify(base64Image));
            router.push("/");
          } else {
            ImageService.requestUserProfileImage(decoded.user_id, decoded.prfimg).then((base64Image) => {
              commit("SET_PRF_IMG_FILE", base64Image);
              localStorage.setItem("currentPrfImgFile", JSON.stringify(base64Image));
              router.push("/");
            }).finally(() => {
              dispatch("subtractLoading");
            });
          }


          // END DUPLICATED

        }
        resolve(response.data);
      })
      .catch(function (err) {
        let { message } = err.response.data;
        dispatch("openSnackbar", {
          message: message,
        });
        dispatch("subtractLoading");
        reject(err);
      });
  });
};

export const resendValidationCode = ({ dispatch }, params) => {
  return new Promise((resolve, reject) => {
    http
      .post("auth/account/resend-code", params)
      .then(function (response) {
        if (response.status === 200) {
          dispatch("subtractLoading");
          let { message } = response.data;
          dispatch("openSnackbar", {
            message: message,
            color: "success",
          });
        }
        resolve(response.data);
      })
      .catch(function (err) {
        let { message } = err.response.data;
        dispatch("openSnackbar", {
          message: message,
        });
        dispatch("subtractLoading");
        reject(err);
      });
  });
};

export const validateAccount = ({ dispatch }, params) => {
  return new Promise((resolve, reject) => {
    http
      .put(
        `auth/account/activate/${params.id_user}/${params.activation_code}`,
        params
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch("subtractLoading");
          let { message } = response.data;
          dispatch("openSnackbar", {
            message: message,
            color: "success",
          });
        }
        resolve(response.data);
      })
      .catch(function (err) {
        let { message } = err.response.data;
        dispatch("openSnackbar", {
          message: message,
        });
        dispatch("subtractLoading");
        reject(err);
      });
  });
};

export const signUp = ({ dispatch, commit }, params) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    if (params.self_image) {
      formData.append("self_image", params.self_image);
    }
    formData.append("date_of_birth", params.date_of_birth);
    formData.append("password", params.password);
    formData.append("name", params.name);
    formData.append("email", params.email);
    formData.append("mobile_number", params.mobile_number);
    formData.append("mobile_country_code", params.mobile_country_code);
    formData.append("mobile_country_iso", params.mobile_country_iso);
    formData.append("gender", params.gender);
    formData.append("agreement_accepted", params.agreement_accepted);

    http
      .post("auth/account/signup", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        if (response.status === 201) {
          dispatch("subtractLoading");

          let user = response.data.data;
          commit("SET_VALIDATION_EMAIL", user.email);
          commit("SET_VALIDATION_USER_ID", user.id);

          let { message } = response.data;
          dispatch("openSnackbar", {
            message: message,
            color: "success",
          });
        }
        resolve(response.data);
      })
      .catch(function (err) {
        let { message } = err.response.data;
        dispatch("openSnackbar", {
          message: message,
        });
        dispatch("subtractLoading");
        reject(err);
      });
  });
};

export const validateForgotPasswordCode = ({ dispatch }, params) => {
  return new Promise((resolve, reject) => {
    http
      .put(
        `auth/account/change-password-consume-code/${params.id}/${params.code}`
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch("subtractLoading");
          let { message } = response.data;
          dispatch("openSnackbar", {
            message: message,
            color: "success",
          });
        }
        resolve(response.data.data.token);
      })
      .catch(function (err) {
        let { message } = err.response.data;
        dispatch("openSnackbar", {
          message: message,
        });
        dispatch("subtractLoading");
        reject(err);
      });
  });
};

export const changePassword = ({ dispatch }, params) => {
  return new Promise((resolve, reject) => {
    http
      .post(
        "auth/account/change-password",
        { password: params.password },
        {
          headers: { Authorization: `Bearer ${params.token}` },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch("subtractLoading");
          let { message } = response.data;
          dispatch("openSnackbar", {
            message: message,
            color: "success",
          });
        }
        resolve(response);
      })
      .catch(function (err) {
        let { message } = err.response.data;
        dispatch("openSnackbar", {
          message: message,
        });
        dispatch("subtractLoading");
        reject(err);
      });
  });
};

export const tryAutoLogin = async ({ commit }) => {
  const currentUserToken = JSON.parse(localStorage.getItem("currentUserToken"));
  const currentUserName = JSON.parse(localStorage.getItem("currentUserName"));
  const currentIsMainAdmin = JSON.parse(localStorage.getItem("currentIsMainAdmin"));
  const currentCompanyId = JSON.parse(localStorage.getItem("currentCompanyId"));
  const currentCompanyName = JSON.parse(localStorage.getItem("currentCompanyName"));
  const currentUserId = JSON.parse(localStorage.getItem("currentUserId"));
  const currentPrfImg = JSON.parse(localStorage.getItem("currentPrfImg"));
  const currentPrfImgFile = JSON.parse(localStorage.getItem("currentPrfImgFile"));
  const currentUserEmail = JSON.parse(localStorage.getItem("currentUserEmail"));
  if (!currentUserToken) {
    return;
  } else {
    commit("SET_USER_TOKEN", currentUserToken);
    commit("SET_USER_NAME", currentUserName);
    commit("SET_IS_MAIN_ADMIN", currentIsMainAdmin)
    commit("SET_COMPANY_ID", currentCompanyId);
    commit("SET_COMPANY_NAME", currentCompanyName);
    commit("SET_USER_ID", currentUserId);
    commit("SET_PRF_IMG", currentPrfImg);
    commit("SET_PRF_IMG_FILE", currentPrfImgFile);
    commit("SET_USER_EMAIL", currentUserEmail);
  }
};

export const logout = ({ commit, dispatch }) => {
  dispatch("disconnectWebSocketNotificationService");
  commit("SET_USER_TOKEN", null);
  localStorage.removeItem("currentUserToken");
  localStorage.removeItem("currentUserName");
  localStorage.removeItem("currentIsMainAdmin");
  localStorage.removeItem("currentCompanyId");
  localStorage.removeItem("currentCompanyName");
  localStorage.removeItem("currentUserId");
  localStorage.removeItem("currentPrfImg");
  localStorage.removeItem("currentPrfImgFile");
  localStorage.removeItem("currentUserEmail");
  router.push("/login");
};
