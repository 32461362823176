<template>
  <div>
    <v-toolbar>
      <v-app-bar-nav-icon @click.stop="drawer = true"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>notifications</v-icon>
      </v-btn>
      <v-badge
        dot
        overlap
        :color="
          this.notificationList && this.notificationList.length > 0 ? 'red' : ''
        "
        offset-x="18"
        offset-y="28"
      >
        <v-btn
          id="notificationActionChatIcon"
          icon
          @click="openNotificationList = !openNotificationList"
        >
          <v-icon
            :color="this.isNotificationServiceConnected ? 'primary' : 'grey'"
            >chat</v-icon
          >
        </v-btn>
      </v-badge>
    </v-toolbar>

    <navigation-drawer-notification
      @closed="
        () => {
          this.openNotificationList = false;
        }
      "
      :openNotificationList="this.openNotificationList"
    />

    <v-navigation-drawer v-model="drawer" expand-on-hover clipped app>
      <template v-slot:prepend>
        <v-img class="ma-3 mt-5" src="@/assets/logo-salvedomi.svg" />
        <v-list>
          <v-list-item class="px-2" link>
            <v-list-item-avatar>
              <v-img
                id="userImageProfile"
                position="block"
                :src="
                  prfImgFile ? prfImgFile : require('@/assets/icons/user.png')
                "
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="text-align: center">
                {{ userName }}
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 10px; text-align: center">
                {{ userEmail }}
              </v-list-item-subtitle>
              <v-list-item-subtitle style="font-size: 10px; text-align: center">
                {{ companyName }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="isMainAdmin"
                style="font-size: 10px; text-align: center"
              >
                <v-chip class="ma-2" color="red" text-color="white">
                  MAIN ADMIN
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>

      <v-list>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="goTo(item.title)"
          >
            <v-list-item-icon>
              <v-icon :size="item.size">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router/index.js";
import NavigationDrawerNotification from "@/components/shared/NavigationDrawerNotification";

export default {
  components: { NavigationDrawerNotification },
  computed: {
    ...mapGetters([
      "userToken",
      "userName",
      "isMainAdmin",
      "companyName",
      "userId",
      "prfImgFile",
      "userEmail",
      "notificationList",
      "isNotificationServiceConnected",
    ]),
  },
  mounted() {
    this.$store.dispatch("connectToNotificationService");
    this.$store.dispatch("updateNotificationList");
    this.addMenuItem();
  },
  methods: {
    goTo(title) {
      switch (title) {
        case "Home":
          if (this.$route.name !== "/") {
            this.selectedItem = 0;
            router.push("/");
          }
          break;
        case "Properties":
          if (this.$route.name !== "property") {
            this.selectedItem = 1;
            router.push("/property");
          }
          break;
        case "Rentals":
          if (this.$route.name !== "rental") {
            this.selectedItem = 2;
            router.push("/rental");
          }
          break;
        case "Management":
          if (this.$route.name !== "management") {
            this.selectedItem = 3;
            router.push("/management");
          }
          break;
        // case "Patient list":
        //   if (this.$route.name !== "patient-list") router.push("/patient-list");
        //   break;
        case "Logout":
          this.$store.dispatch("logout");
          break;
      }
    },
    addMenuItem() {
      this.items.push({ title: "Home", icon: "home", size: "20" })
      this.items.push({ title: "Properties", icon: "home_work", size: "20" })
      this.items.push({ title: "Rentals", icon: "real_estate_agent", size: "20" })
      if (this.isMainAdmin) {
        this.items.push({ title: "Management", icon: "groups", size: "20" })
      }
      this.items.push({ title: "Logout", icon: "fas fa-sign-out-alt", size: "20" })
    },
  },
  data() {
    return {
      drawer: null,
      hasUnreadNotification: false,
      openNotificationList: false,
      selectedItem: 0,
      items: [],
    };
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>