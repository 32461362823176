<template>
  <div>
    <v-layout>
      <v-flex>
        <v-combobox
          outlined
          prepend-inner-icon="search"
          label="Search Address"
          color="primary"
          v-model="addressSelected"
          return-object
          :items="searchAddresResultOptions"
          :search-input.sync="search"
          hide-no-data
          no-filter
          hide-details
          item-text="description"
          item-value="place_id"
        ></v-combobox>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex md12>
        <v-card
          outlined
          :id="this.mapElementId"
          :height="
            this.selectedModelToAddress.latitude &&
            this.selectedModelToAddress.longitude
              ? '300px'
              : '0px'
          "
        >
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout
      v-if="
        this.selectedModelToAddress.latitude &&
        this.selectedModelToAddress.longitude
      "
    >
      <v-flex md12>
        <v-switch
          prepend-icon="pin_drop"
          label="Pick location manually"
          color="primary"
          value="red"
          center
          v-model="manualLocation"
          hide-details
        >
        </v-switch>

        <v-card-text v-if="manualLocation" style="font-size: 12px">
          <v-row>
            <span>
              Click on map to edit current location. (All address fields must be
              edited manually)</span
            >
          </v-row>
        </v-card-text>
      </v-flex>
    </v-layout>
    <v-divider class="pa-2 mb-2"></v-divider>
  </div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyCVubNG7qZPsNU5YKNuALWF7dE5CUfDvC8",
  version: "weekly",
  libraries: ["places"],
});

export default {
  props: {
    selectedModelToAddress: {},
  },

  data: () => ({
    addressSelected: null,
    loading: false,
    search: null,
    searchAddresResultOptions: [],
    manualLocation: false,
    mapElementId: Math.random(),
    rules: {
      required: (value) => !!value || "Required field",
    },
  }),
  watch: {
    addressSelected: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          return;
        }
        this.selectedModelToAddress.free_form_address = newValue.description;

        if (!this.currrentGoogleInstance || !this.map) {
          console.log("not google instance");
          loader.load().then((google) => {
            this.currrentGoogleInstance = google;
            const myLatlng = { lat: -25.363, lng: 131.044 };
            // console.log(this.mapElementId);

            this.map = new this.currrentGoogleInstance.maps.Map(
              document.getElementById(this.mapElementId),
              {
                zoom: 15,
                center: myLatlng,
              }
            );

            this.addListenerClick();

            this.getPlaceByID(newValue.place_id);
          });
        } else {
          this.getPlaceByID(newValue.place_id);
        }
      },
    },
    selectedModelToAddress: {
      immediate: true,
      handler(newValue) {
        
        this.addressSelected = null;
        
        this.manualLocation = false;
        
        if (newValue.latitude && newValue.longitude) {
          this.buildMap();
        }
      },
    },
    search(val) {
      val && val !== this.select && this.searchAddressOptions(val);
    },
  },
  methods: {
    getPlaceByID(place_id) {
      var requestById = {
        placeId: place_id,
        fields: ["address_component", "geometry"], // geometry
      };

      let placeIdService =
        new this.currrentGoogleInstance.maps.places.PlacesService(this.map);
      placeIdService.getDetails(requestById, (place, status) => {
        if (
          status ==
          this.currrentGoogleInstance.maps.places.PlacesServiceStatus.OK
        ) {
          this.selectedModelToAddress.latitude = place.geometry.location.lat();
          this.selectedModelToAddress.longitude = place.geometry.location.lng();

          this.currentMarker ? this.currentMarker.setMap(null) : null;
          this.currentMarker = new this.currrentGoogleInstance.maps.Marker({
            position: place.geometry.location,
            map: this.map,
            title: "Property Location!",
            label: this.selectedModelToAddress.free_form_address,
          });

          this.map.setCenter(place.geometry.location);
          this.map.panTo(place.geometry.location);

          this.selectedModelToAddress.state_province = null;  
          this.selectedModelToAddress.city = null;
          this.selectedModelToAddress.country = null;
          this.selectedModelToAddress.country_code_iso3 = null;
          this.selectedModelToAddress.postal_code = null;

          place.address_components.forEach((item) => {
            // console.log(item);

            if (item.types.includes("administrative_area_level_1")) {
              this.selectedModelToAddress.state_province = item.short_name;
              this.$emit("input", this.selectedModelToAddress.state_province);
            } else if (item.types.includes("locality")) {
              this.selectedModelToAddress.city = item.long_name;
              this.$emit("input", this.selectedModelToAddress.city);
            } else if (item.types.includes("country")) {
              this.selectedModelToAddress.country = item.long_name;
              this.$emit("input", this.selectedModelToAddress.country);
              this.selectedModelToAddress.country_code_iso3 = item.short_name;
              this.$emit(
                "input",
                this.selectedModelToAddress.country_code_iso3
              );
            } else if (item.types.includes("postal_code")) {
              this.selectedModelToAddress.postal_code = item.long_name;
              this.$emit("input", this.selectedModelToAddress.postal_code);
            }
          });
          // NEVER REMOVE IT! IT SAVE LIVES! LOT OF LIFES!
          this.$forceUpdate();
          this.$emit("processAddressDataOnModel", this.selectedModelToAddress);

        }
      });
    },

    searchAddressOptions(text) {
      if (!this.currrentGoogleInstance) {
        loader.load().then((google) => {
          this.currrentGoogleInstance = google;
          this.searchAddressToComplete(text);
        });
      } else {
        this.searchAddressToComplete(text);
      }
    },

    searchAddressToComplete(text) {
      const service12 =
        new this.currrentGoogleInstance.maps.places.AutocompleteService();

      service12.getQueryPredictions({ input: text }, (results, status) => {
        console.log(status);
        // console.log(results);
        this.searchAddresResultOptions = results;
        
      });
    },

    setLocation(lat, lng) {
      this.selectedModelToAddress.latitude = lat;
      this.selectedModelToAddress.longitude = lng;
      this.$forceUpdate();
    },
    buildMap() {
      
      loader
        .load()
        .then((google) => {
          // init
          this.currrentGoogleInstance = google;
          const myLatlng = {
            lat: this.selectedModelToAddress.latitude,
            lng: this.selectedModelToAddress.longitude,
          };
          this.map = new this.currrentGoogleInstance.maps.Map(
            document.getElementById(this.mapElementId),
            {
              zoom: 15,
              center: myLatlng,
            }
          );

          this.currentMarker = new this.currrentGoogleInstance.maps.Marker({
            position: myLatlng,
            map: this.map,
            title: "Property Location!",
            label: this.selectedModelToAddress.free_form_address,
          });

          this.map.setCenter(myLatlng);
          this.map.panTo(myLatlng);

          // map.addListener("center_changed", () => {
          //   console.log("click3");
          //   // 3 seconds after the center of the map has changed, pan back to the
          //   // marker.
          //   window.setTimeout(() => {
          //     map.panTo(marker.getPosition());
          //   }, 3000);
          // });

          // marker.addListener("click", () => {
          //   console.log("click");
          //   map.setZoom(8);
          //   map.setCenter(marker.getPosition());
          // });

          // Create the initial InfoWindow.
          // let infoWindow = new google.maps.InfoWindow({
          //   content: "Click the map to get Lat/Lng!",
          //   position: myLatlng,
          // });

          // infoWindow.open(map);

          // Configure the click listener.
          this.addListenerClick();

          //end
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addListenerClick() {
      // Configure the click listener.
      this.map.addListener("click", (mapsMouseEvent) => {
        if (!this.manualLocation) {
          return;
        }

        let currentLabel = this.currentMarker.label;
        this.setLocation(
          mapsMouseEvent.latLng.lat(),
          mapsMouseEvent.latLng.lng()
        );

        this.currentMarker.setMap(null);
        this.currentMarker = new this.currrentGoogleInstance.maps.Marker({
          position: mapsMouseEvent.latLng,
          title: "Property Location!",
          label: currentLabel,
        });

        // To add the marker to the map, call setMap();
        this.currentMarker.setMap(this.map);

        // map.setCenter(mapsMouseEvent.latLng);
        window.setTimeout(() => {
          this.map.panTo(this.currentMarker.getPosition());
        }, 500);
      });
    },
  },
};
</script>