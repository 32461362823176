<template>
  <div>
    <v-expansion-panels focusable :value="expanded">
      <v-expansion-panel>
        <v-expansion-panel-header
          >{{ title }} ({{ videos.length }})</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row>
            <v-container>
              <video-upload
                :selectedParent="selectedParent"
                :endpoint="endpoint"
                @videoUploaded="reloadGalleryImages()"
                @displaySavePropertyDialog="saveParentDialogToggle = true"
              ></video-upload>
            </v-container>
          </v-row>
          <v-row align-content="center" justify="center">
            <v-col
              v-for="video in videos"
              :key="video.id"
              class="d-flex child-flex "
              cols="5"
            >
              <div class="video-card" v-if="video.streaming_endpoint">
                <video-player-hls
                class="mb-2"
                  :src="video.streaming_endpoint + '.' + video.extension"
                ></video-player-hls>
                <v-btn
                  @click="deleteVideo(video.id)"
                  color="red"
                  outlined
                  v-if="video.streaming_endpoint"
                >
                  <v-icon style="margin-right: 5px"> mdi-delete </v-icon
                  >Delete Video</v-btn
                >
              </div>

              <div v-else class="not-available-box">
                <p>Video is being processed and will be available soon.</p>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- SAVE PROPERTY BEFORE ADDING IMAGES DIALOG -->
    <v-dialog v-model="saveParentDialogToggle" persistent max-width="450">
      <v-card>
        <v-card-title class="text-h5">Save Property</v-card-title>
        <v-card-text
          >You must save the current Property before adding pictures or
          videos.<br />
          <strong>Do you wish to save the current property now? </strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="saveParentDialogToggle = false"
          >
            No, Keep editing
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="(saveParentDialogToggle = false), saveParent()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE DIALOG -->
    <v-row justify="center">
      <v-dialog v-model="deleteToggle" persistent max-width="450">
        <v-card>
          <v-card-title class="text-h5"> Delete Video </v-card-title>
          <v-card-text
            >Are you sure you want to delete the current video? <br />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteToggle = false">
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="(deleteToggle = false), deleteVideoConfirmed()"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import VideoUpload from "@/components/media/VideoUpload";
import MediaService from "@/services/mediaService";
import VideoPlayerHls from "@/components/media/VideoPlayerHls";

export default {
  mounted() {
    if (this.selectedParent.id) {
      this.loadAllMedia(this.selectedParent.id);
    }
  },
  components: {
    VideoUpload,
    VideoPlayerHls,
  },
  props: {
    title: null,
    endpoint: null,
    expanded: {
      default: 1,
    },
    selectedParent: null,
    withUpload: {
      default: false,
    },
  },
  data() {
    return {
      deleteToggle: false,
      clickedVideoId: null,
      videos: [],
      saveParentDialogToggle: false,
    };
  },
  methods: {
    loadAllMedia(id) {
      MediaService.listMedia(this.endpoint, id)
        .then(
          (response) => {
            this.videos = response.data.filter((media) => {
              console.log(media.streaming_endpoint + "." + media.extension);
              return media.type.includes("x-mpegURL");
              //  && media.ready_for_streaming == true
            });
          },
          (error) => {
            this.$store.dispatch("openSnackbar", {
              message: "Error: Please, contact support: " + error,
            });
          }
        )
        .finally(() => {
          this.$store.dispatch("subtractLoading");
        });
    },
    deleteVideo(videoIdToDelete) {
      this.deleteToggle = true;
      this.clickedVideoId = videoIdToDelete;
    },
    deleteVideoConfirmed() {
      MediaService.deleteMedia(this.endpoint, this.clickedVideoId)
        .then(
          () => {
            this.removeMediaFromList();
          },
          (error) => {
            this.$store.dispatch("openSnackbar", {
              message: "Error: " + error.response.data.message,
            });
          }
        )
        .finally(() => {
          this.$store.dispatch("subtractLoading");
        });
    },
    removeMediaFromList() {
      this.videos = this.videos.filter((video) => {
        return video.id !== this.clickedVideoId;
      });
    },
    reloadGalleryImages() {
      this.loadAllMedia(this.selectedParent.id);
    },
    saveParent() {
      this.$emit("saveParent", false);
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}

.not-available-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  background-color: black;
  color: white;
}

.not-available-box p {
  text-align: center;
}

.video-card .v-btn {
  margin: 0px !important;
  width: 100%;
}
</style>