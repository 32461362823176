<template>
  <v-container>
    <!-- <div class="page-header">Properties</div> -->
    <property-list></property-list>
  </v-container>
</template>

<script>
import PropertyList from "@/components/property/PropertyList.vue";
export default {
  components: { PropertyList },
  name: "PropertyWrapper",
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
