<template>
  <div>
    <button v-if="image" @click="displayModal = true">
      <img :class="classImage"  :src="image" />
    </button>
      <div v-else-if="loading">Loading image...</div>
      <div v-else>No image found.</div>
    <v-dialog v-model="displayModal" persistent>
      <button @click="displayModal = false" style="color: white; display: block; z-index: 1; /* Sit on top */">CLOSE</button>
        <img :src="image" style="
        display: block;
        width:auto; 
        height: auto;
        margin: auto;
        " />
    </v-dialog>
  </div>
</template>

<script>
import ImageService from "@/services/imageService";

export default {
  props: {
    imageName: {
      type: String,
      default: null,
    },
    classImage: {
      type: String,
      default: null,
    },
  },
  methods: {
    requestChatImage() {
      ImageService.requestImage(`platform/event/chat/media/${this.imageName}`)
        .then((base64Image) => {
          this.image = base64Image;
          setTimeout(() => this.$emit("scrollDownChat"), 100);
        })
        .finally(() => {
          this.$store.dispatch("subtractLoading");
        });
    },
  },
  data() {
    this.requestChatImage();

    return {
      displayModal: false,
      loading: true,
      image: null,
    };
  },
};
</script>