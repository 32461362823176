import alertSound from "@/assets/sound/alert-tone.wav";
import NotificationService from "@/services/notificationService";

export const updateNotificationList = async ({ commit, dispatch }) => {
    const result = await NotificationService.listUnreadNotificationByLoggedUser(dispatch);
    commit("SET_NOTIFICATION_LIST", (result.data ? result.data : []));
    dispatch("subtractLoading");
};

export const disconnectWebSocketNotificationService = async ({ commit, dispatch, getters }) => {

    if (getters.notificationServiceConnection) {
        getters.notificationServiceConnection.close();
    }
    commit("SET_NOTIFICATION_SERVICE_CONNECTION", null);
    dispatch("subtractLoading");
};

export const connectToNotificationService = ({ commit, dispatch, getters }) => {

    if (getters.notificationServiceConnection) {
        console.log("A socket connection is already in progress.");
        return;
    }

    if(!getters.userToken){
        console.log("Credentials not available to connect on socket service.");
        return;
    }
    
    let connection = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_SERVER}/notification/supportconnect/ws?token=${getters.userToken}`);

    // Listen for possible errors
    connection.addEventListener('error', function (event) {
        console.log('WebSocket error: ', event);
    });


    connection.onmessage = async (event) => {
        console.log("------receive messageeeeee");
        let audio = new Audio(alertSound);
        await dispatch("updateNotificationList");
        
        audio.play().catch(()=>{
            document.getElementById('notificationActionChatIcon').click()
        });
        event;


    };

    connection.onclose = (event) => {
        console.log("Connection closed...");
        commit("SET_NOTIFICATION_SERVICE_CONNECTED", false);
        dispatch("subtractLoading");
        commit("SET_NOTIFICATION_SERVICE_CONNECTION", null);
        dispatch("subtractLoading");
        
        if (event.code === 1006) {
            console.log(" event code: ");
            console.log(event.code);
            console.log("reconnecting...");
            setTimeout(function () { dispatch("connectToNotificationService"); }, 5000);
        }

    };

    connection.onopen = (event) => {
        event;
        /*
        Uncaught (in promise) DOMException: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD
        */
        commit("SET_NOTIFICATION_SERVICE_CONNECTED", true);
        dispatch("subtractLoading");
        commit("SET_NOTIFICATION_SERVICE_CONNECTION", connection);
        dispatch("subtractLoading");
        // console.log('------ CONN');
        // console.log(getters.notificationServiceConnection);
        dispatch("updateNotificationList");
        console.log("Successfully connected to the echo websocket server...");
    };


};