<template>
  <div>
    <v-navigation-drawer
      v-model="showNotificationList"
      right
      clipped
      app
      width="500px"
    >
      <template v-slot:prepend>
        <v-list>
          <v-list-item class="px-2" link>
            <v-btn class="mr-1" icon @click="$emit('closed')">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-badge
              avatar
              bordered
              overlap
              :color="isNotificationServiceConnected ? 'green':'grey' "
              offset-x="18"
              offset-y="28"
            >
              <v-list-item-avatar class="mr-2">
                <v-img
                  id="userImageProfile"
                  position="block"
                  :src="
                    prfImgFile ? prfImgFile : require('@/assets/icons/user.png')
                  "
                />
              </v-list-item-avatar>
            </v-badge>

            <v-list-item-content class="ml-2">
              <v-list-item-title>
                {{ userName }}
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 10px; ">
                {{ userEmail }}
              </v-list-item-subtitle>
              <v-list-item-subtitle style="font-size: 10px;">
                {{ companyName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>

      <v-card class="mx-auto" max-width="500">
        <v-card dark flat>
          <!-- <v-btn absolute bottom color="pink" right fab>
            <v-icon>mdi-plus</v-icon>
          </v-btn> -->
          <!-- <v-card-title class="pa-2 purple lighten-3">
            <v-btn icon>
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <h3 class="text-h6 font-weight-light text-center grow">Timeline</h3>
            <v-avatar>
              <v-img
                src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
              ></v-img>
            </v-avatar>
          </v-card-title> -->
          <v-img
            max-height="100px"
            src="https://cdn.vuetifyjs.com/images/cards/forest.jpg"
            gradient="to top, rgba(0,0,0,.44), rgba(0,0,0,.44)"
          >
            <v-container class="fill-height">
              <v-row align="center">
                <strong class="text-h1 font-weight-regular mr-6">{{
                  new Date().getDate()
                }}</strong>
                <v-row justify="end">
                  <div class="text-h5 font-weight-light">
                    {{ this.getWeekDay }}
                  </div>
                  <div class="text-uppercase font-weight-light mr-5">
                    {{ this.getMonthName }} {{ new Date().getFullYear() }}
                  </div>
                </v-row>
              </v-row>
            </v-container>
          </v-img>
        </v-card>
        <v-card-text class="py-0">
          
          <v-timeline v-for="(item, i) in this.notificationList" :key=i  dense>

            <v-timeline-item color="primary"  icon="chat" fill-dot>
              <v-card flat>
                  <event-card :eventId="item.key" />
              </v-card>
            </v-timeline-item>

          </v-timeline>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventCard from '../notification/EventCard.vue';

export default {
  components: { EventCard },
  computed: {
    ...mapGetters([
      "userToken",
      "userName",
      "companyName",
      "userId",
      "prfImgFile",
      "userEmail",
      "notificationList",
      "isNotificationServiceConnected",
    ]),
    getWeekDay: function () {
      const weekList = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return weekList[new Date().getDay()];
    },
    getMonthName: function () {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return months[new Date().getMonth()];
    },
  },
  props: {
    openNotificationList: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    openNotificationList: {
      handler() {
        this.showNotificationList = this.openNotificationList;
      },
    },
  },
  methods: {
  },
  data() {
    return {
      selectedItem: 0,
      showNotificationList: false,
      items: [
        { title: "Home", icon: "home", size: "20" },
        { title: "Properties", icon: "home_work", size: "20" },
        { title: "Rentals", icon: "real_estate_agent", size: "20" },
        // { title: "Patient list", icon: "home", size: "20" },
        { title: "Logout", icon: "fas fa-sign-out-alt", size: "20" },
      ],
    };
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>