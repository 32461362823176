import http from "@/http";

class MediaService {
    constructor() {
        this.baseUrl = 'platform'
        this.pageSize = 50
    }

    listMedia(endpoint, id) {
        return http.get(`${this.baseUrl}/${endpoint}-media?id_${endpoint}=${id}&page=1&page_size=${this.pageSize}`)
            .then(({ data }) => {
                return data
            })
    }

    getProtectedImageLink(endpoint, id, extension) {
        return http.get(`${this.baseUrl}/${endpoint}-media/media-link/${id}.${extension}`)
            .then(({ data }) => {
                return data
            })
    }

    uploadMedia(endpoint, formData) {
        return http.post(`${this.baseUrl}/${endpoint}-media`, formData)
            .then(({ data }) => {
                return data
            })
    }

    deleteMedia(endpoint, id) {
        return http.delete(`${this.baseUrl}/${endpoint}-media/${id}`, { data: { description: "deleted" } })
            .then(({ data }) => {
                return data
            })
    }
}

export default new MediaService()