<template>
  <div>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Rentals</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white primary--text" @click="showForm = true">
          <v-icon> real_estate_agent </v-icon>
          <v-icon> add </v-icon>
          New Rental
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <!-- <v-spacer></v-spacer> -->
        <v-container>
          <v-row>
            <v-text-field
              v-model="searchText"
              @keyup.enter.native="search"
              @keyup.tab.native="search"
              label="Type text to search for rentals..."
              single-line
              hide-details
            ></v-text-field>
            <v-btn
              @click="search"
              class="ma-2"
              color="primary"
              outlined
              width="100"
            >
              <v-icon style="margin-right: 5px"> mdi-magnify </v-icon
              >Search</v-btn
            >
          </v-row>
        </v-container>
      </v-card-title>

      <v-data-table
        :headers="eventHeaders"
        :items="events"
        item-key="name"
        hide-default-footer
      >
        <template v-slot:item.property_label="{ item }">
          <span style="font-size: 0.9em">{{ item.property_label }}</span>
        </template>
        <template v-slot:item.address="{ item }">
          <span style="font-size: 0.9em">{{ item.free_form_address }}</span>
          <br />
          <span style="font-size: 0.9em">{{ item.address_detail }}</span>
        </template>
        <template v-slot:item.label="{ item }">
          <span style="font-size: 0.9em">{{ item.label }}</span>
        </template>
        <template v-slot:item.main_guest_name="{ item }">
          <span style="white-space: nowrap; font-size: 0.9em">{{
            item.main_guest_name
          }}</span>
          <br />
          <span style="white-space: nowrap; font-size: 0.7em">{{
            item.main_guest_email
          }}</span>
        </template>
        <template v-slot:item.checkin-checkout="{ item }">
          <span style="font-size: 0.9em" class="text-truncate">{{
            momentDateTime(
              item.start_datetime,
              item.time_zone_id,
              item.time_zone_name
            )
          }}</span>
          <br />
          <span style="font-size: 0.9em" class="text-truncate">{{
            momentDateTime(
              item.end_datetime,
              item.time_zone_id,
              item.time_zone_name
            )
          }}</span>
        </template>
        <template v-slot:item.timezone="{ item }">
          <span style="white-space: nowrap; font-size: 0.8em">{{
            item.time_zone_id ? item.time_zone_name : ""
          }}</span>
          <br />
          <span style=" white-space: nowrap; font-size: 0.8em">{{
            item.time_zone_id ? " (" + item.time_zone_id + ")" : ""
          }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="openChat(item)">mdi-chat</v-icon>
          <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon class="mr-2" @click="deleteItem(item)">mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <!-- Pagination area -->
    <div class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.page"
                @input="listEventsFromCompany()"
                class="my-4"
                :length="pagination.maxPage"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- DELETE DIALOG -->
    <v-row justify="center">
      <v-dialog
        v-model="deleteItemToggle"
        persistent
        max-width="450"
        v-if="selectedEvent"
      >
        <v-card>
          <v-card-title class="text-h5"> Delete Rental </v-card-title>
          <v-card-text
            >Are you sure you want to delete the following rental?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="(deleteItemToggle = false), resetSelectedEvent()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="
                (deleteItemToggle = false),
                  deleteItemConfirmed(selectedEvent.id)
              "
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <!-- <v-dialog v-model="showForm" persistent max-width="700px"> -->
      <event-form
        @closed="formClosed"
        @saved="formSaved"
        :showForm="this.showForm"
        :selectedEvent="this.selectedEvent"
        :preSelectedProperty="this.preSelectedProperty"
      ></event-form>
      <!-- </v-dialog> -->
    </v-row>
    <v-row justify="center">
      <test-chat
        @closed="chatClosed"
        :showChat="this.showChat"
        :selectedEvent="this.selectedEvent"
      ></test-chat>
    </v-row>
  </div>
</template>

<script>
import EventService from "@/services/eventService";
import EventForm from "@/components/events/EventForm";
import TestChat from "@/components/chat/TestChat";
import PropertyService from "@/services/propertyService";
import moment from "moment";
import "moment-timezone";

export default {
  components: {
    EventForm,
    TestChat,
  },
  async mounted() {
    if (this.$route.query.propertyId) {
      try {
        let result = await PropertyService.getPropertyById(
          this.$route.query.propertyId
        );
        this.preSelectedProperty = result.data;
        this.showForm = true;
      } catch (error) {
        let msg =
          error.response.status == 422
            ? 'Field "' +
              error.response.data.detail[0].loc[1] +
              '" ' +
              error.response.data.detail[0].msg
            : error.response.data.message;
        this.$store.dispatch("openSnackbar", {
          message: "Error: " + msg,
        });
      } finally {
        this.$store.dispatch("subtractLoading");
      }
    }
  },
  created() {
    this.resetSelectedEvent();
    this.listEventsFromCompany();
  },
  data() {
    return {
      searchText: "",
      pagination: {
        page: 1,
        maxPage: 1,
      },
      showForm: false,
      showChat: false,
      dialog: false,
      deleteItemToggle: false,
      defaultItem: {
        id: "",
        id_property: "",
        main_guest_name: "",
        main_guest_email: "",
        label: "",
        description: "",
        start_datetime: "",
        end_datetime: "",
        available_since: "",
        max_members: 1,
        url_rental_platform: "",
      },
      preSelectedProperty: {
        id: "",
        label: "",
      },
      eventHeaders: [
        { text: "Property", align: "start", value: "property_label" },
        { text: "Address", align: "start", value: "address" },
        { text: "Label", align: "start", value: "label" },
        { text: "Main Guest", align: "start", value: "main_guest_name" },
        {
          text: "Check-In/Check-Out",
          align: "start",
          value: "checkin-checkout",
        },
        { text: "Timezone", align: "center", value: "timezone" },
        { text: "", align: "start", value: "actions" },
      ],
      events: [],
    };
  },
  methods: {
    momentDateTime(dateTime, time_zone_id) {
      return time_zone_id
        ? moment(dateTime).tz(time_zone_id).locale(time_zone_id).format("llll")
        : moment(dateTime).format("llll");
    },
    openChat(item) {
      this.showChat = true;
      this.selectedEvent = Object.assign({}, item);
    },
    async editItem(item) {
      try {
        let result = await EventService.getEventById(item.id);
        this.selectedEvent = Object.assign({}, result.data);
        this.showForm = true;
      } catch (error) {
        let msg =
          error.response.status == 422
            ? 'Field "' +
              error.response.data.detail[0].loc[1] +
              '" ' +
              error.response.data.detail[0].msg
            : error.response.data.message;
        this.$store.dispatch("openSnackbar", {
          message: "Error: " + msg,
        });
      } finally {
        this.$store.dispatch("subtractLoading");
      }
    },
    formSaved() {
      this.showForm = false;
      this.resetSelectedEvent();
      this.resetPreSelectedProperty();
      this.listEventsFromCompany();
    },
    chatClosed() {
      this.showChat = false;
      this.resetPreSelectedProperty();
      this.resetSelectedEvent();
    },
    formClosed() {
      this.showForm = false;
      this.resetPreSelectedProperty();
      this.resetSelectedEvent();
    },
    deleteItem(item) {
      this.deleteItemToggle = true;
      this.selectedEvent = item;
    },
    deleteItemConfirmed(id) {
      EventService.deleteEvent(id)
        .then(
          () => {
            this.listEventsFromCompany(1);
          },
          (error) => {
            console.log(error.response.data.detail);
            this.$store.dispatch("openSnackbar", {
              message: "Error: " + error.response.data.message,
            });
          }
        )
        .finally(() => {
          this.$store.dispatch("subtractLoading");
          this.resetSelectedEvent();
        });
    },
    resetSelectedEvent() {
      this.selectedEvent = Object.assign({}, this.defaultItem);
    },
    resetPreSelectedProperty() {
      this.preSelectedProperty = Object.assign({}, {});
    },
    search() {
      this.pagination.page = 1;
      this.listEventsFromCompany();
    },
    listEventsFromCompany() {
      EventService.listEventsFromCompany(this.pagination.page, this.searchText)
        .then(
          (success) => {
            this.events = success.data;
            this.pagination.maxPage = success.max_page;
          },
          (error) => {
            this.$store.dispatch("openSnackbar", {
              message: "Error: Please, contact support: " + error,
            });
          }
        )
        .finally(() => {
          this.$store.dispatch("subtractLoading");
        });
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
