import http from "@/http";

class EventService {
    constructor() {
        this.baseUrl = 'platform/event'
        this.pageSize = 10
    }

    listEventsFromCompany(page, text_search) {
        var listCompanyUrl = `${this.baseUrl}?page=${page}&page_size=${this.pageSize}`
        if (text_search.length>0) {
            listCompanyUrl += `&text_search=${text_search}`
        }
        return http.get(listCompanyUrl)
            .then(({ data }) => {
                return data
            })
    }

    deleteEvent(id) {
        return http.delete(`${this.baseUrl}/${id}`, { data: { description: "deleted" } })
            .then(({ data }) => {
                return data
            })
    }

    getEventById(id) {
        return http.get(`${this.baseUrl}/${id}?from_rental=false`)
            .then(({ data }) => {
                return data
            }).catch((err) => {
            throw err;
           });
    }

    addEvent(event) {
        return http.post(`${this.baseUrl}`, event)
            .then(({ data }) => {
                return data
            })
    }

    editEvent(id, event) {
        return http.put(`${this.baseUrl}/${id}`, event)
            .then(({ data }) => {
                return data
            })
    }
}

export default new EventService()