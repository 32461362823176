import http from "@/http";

class PropertyManagerService {
    constructor() {
        this.baseUrl = 'platform/property-manager'
        this.pageSize = 100
    }

    async addGroupAsPropertyManager(dispatch, propertyId, groupId) {

        const item = {
            "id_property": propertyId,
            "id_group": groupId
        }
        return http.post(`${this.baseUrl}`, item)
            .then((response) => {
                dispatch("openSnackbar", {
                    message: `Group successfully added as Manager.`,
                    color: "success",
                });
                return response
            }).catch((error) => {

                let msg =
                    error.response.status == 422
                        ? 'Field "' +
                        error.response.data.detail[0].loc[1] +
                        '" ' +
                        error.response.data.detail[0].msg
                        : error.response.data.message;
                dispatch("openSnackbar", {
                    message: msg,
                });
                return { "data": {} };
            });

    }

    async loadUsersAndGroupsByPropertyId(dispatch, id_property) {
        try {
            return http.get(`${this.baseUrl}/${id_property}`)
                .then(({ data }) => {
                    return data
                }).catch((err) => {
                    throw err;
                });
        } catch (error) {
            let msg =
                error.response.status == 422
                    ? 'Field "' +
                    error.response.data.detail[0].loc[1] +
                    '" ' +
                    error.response.data.detail[0].msg
                    : error.response.data.message;
            dispatch("openSnackbar", {
                message: "Error: " + msg,
            });
            return { "data": [] };
        }
    }

    removeManagementProperty(dispatch, id) {
        return http.delete(`${this.baseUrl}/${id}`)
            .then(({ data }) => {
                dispatch("openSnackbar", {
                    message: `Management removed successfully.`,
                    color: "success",
                  });
                return data
            }).catch((error) => {
                let msg =
                    error.response.status == 422
                        ? 'Field "' +
                        error.response.data.detail[0].loc[1] +
                        '" ' +
                        error.response.data.detail[0].msg
                        : error.response.data.message;
                dispatch("openSnackbar", {
                    message: msg,
                });
                return { "data": {} };
            });
    }
}

export default new PropertyManagerService()