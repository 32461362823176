var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',[_c('v-flex',[_c('v-combobox',{attrs:{"outlined":"","prepend-inner-icon":"search","label":"Search Address","color":"primary","return-object":"","items":_vm.searchAddresResultOptions,"search-input":_vm.search,"hide-no-data":"","no-filter":"","hide-details":"","item-text":"description","item-value":"place_id"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.addressSelected),callback:function ($$v) {_vm.addressSelected=$$v},expression:"addressSelected"}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"md12":""}},[_c('v-card',{attrs:{"outlined":"","id":this.mapElementId,"height":this.selectedModelToAddress.latitude &&
          this.selectedModelToAddress.longitude
            ? '300px'
            : '0px'}})],1)],1),(
      this.selectedModelToAddress.latitude &&
      this.selectedModelToAddress.longitude
    )?_c('v-layout',[_c('v-flex',{attrs:{"md12":""}},[_c('v-switch',{attrs:{"prepend-icon":"pin_drop","label":"Pick location manually","color":"primary","value":"red","center":"","hide-details":""},model:{value:(_vm.manualLocation),callback:function ($$v) {_vm.manualLocation=$$v},expression:"manualLocation"}}),(_vm.manualLocation)?_c('v-card-text',{staticStyle:{"font-size":"12px"}},[_c('v-row',[_c('span',[_vm._v(" Click on map to edit current location. (All address fields must be edited manually)")])])],1):_vm._e()],1)],1):_vm._e(),_c('v-divider',{staticClass:"pa-2 mb-2"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }