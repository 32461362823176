<template>
  <div max-width="700px">
    <v-dialog v-model="showForm" scrollable persistent max-width="1000px">
      <!-- FORM -->
      <v-card>
        <dialog-header
          :title="'Manage Property'"
          @closed="close"
        ></dialog-header>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout>
              <v-container>
                <span class="category-header">Property</span>
                <v-divider class="pa-2 mb-2"></v-divider>

                <v-layout>
                  <v-flex sm6 md6>
                    <v-select
                      class="pr-2"
                      outlined
                      prepend-inner-icon="list"
                      label="Property Type:"
                      v-model="selectedProperty.property_type"
                      :items="types"
                      item-text="label"
                      item-value="value"
                      persistent-hint
                      :rules="[rules.required]"
                    ></v-select>
                  </v-flex>
                  <v-flex sm6 md6>
                    <v-text-field
                      class="pr-2"
                      outlined
                      prepend-inner-icon="house"
                      label="Property Label"
                      color="primary"
                      v-model="selectedProperty.label"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength="255"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <address-property-form
                  :selectedModelToAddress="this.selectedProperty"
                />
                <v-layout>
                  <v-flex sm12 md12>
                    <v-select
                      class="pr-2"
                      outlined
                      prepend-inner-icon="list"
                      label="Property Owner:"
                      v-model="selectedPropertyOwnerId"
                      :items="propertyOwnerList"
                      :disabled="this.selectedProperty.id != ''"
                      item-text="company_name"
                      item-value="company_id"
                      :append-outer-icon="'person_add_alt'"
                      
                    ></v-select>
                  </v-flex>
                </v-layout>

                <!-- PROPERTY MANAGEMENT GROUPS AND USERS -->
                <v-row>
                  <v-container>
                    <span>Property permissions</span>
                    <v-divider class="pa-2 mb-2"></v-divider>
                    <property-management-expansion-panel
                      :idOwnerCompany="this.selectedPropertyOwnerId"
                      :selectedProperty="this.selectedProperty"
                      @saveCurrentProperty="save"
                      @updateManagementGroup="updateManagementGroup"
                    ></property-management-expansion-panel>
                  </v-container>
                </v-row>

                <!-- DETAILS -->
                <v-row>
                  <v-container>
                    <span>Property details:</span>
                    <v-divider class="pa-2 mb-2"></v-divider>
                    <detail-group-expansion-panel
                      :idProperty="selectedProperty.id"
                      @saveCurrentProperty="save"
                    ></detail-group-expansion-panel>
                  </v-container>
                </v-row>
                <v-row>
                  <v-container>
                    <span>Property images</span>
                    <v-divider class="pa-2 mb-2"></v-divider>
                    <v-row>
                      <v-container>
                        <image-gallery
                          :key="this.reloadGallery"
                          :title="'Images'"
                          :endpoint="'property'"
                          :selectedParent="this.selectedProperty"
                          @saveParent="save"
                        ></image-gallery>
                      </v-container>
                    </v-row>
                  </v-container>
                </v-row>
                <v-row>
                  <v-container>
                    <span>Property videos</span>
                    <v-divider class="pa-2 mb-2"></v-divider>
                    <v-layout>
                      <v-flex md12>
                        <div class="text-center">
                          <v-row>
                            <v-container>
                              <video-gallery
                                :title="'Videos'"
                                :endpoint="'property'"
                                :selectedParent="this.selectedProperty"
                                v-if="showForm"
                                @saveParent="save"
                              ></video-gallery>
                            </v-container>
                          </v-row>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-row>
              </v-container>
            </v-layout>
          </v-form>

          <!-- END CONTENT DIALOG -->
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { parseJwt } from "../../utils/JWTDecoder";
import DialogHeader from "@/components/shared/DialogHeader";
import VideoGallery from "@/components/media/VideoGallery";
import PropertyService from "@/services/propertyService";
import ImageGallery from "@/components/media/ImageGallery";
import PropertyManagementExpansionPanel from "@/components/management/PropertyManagementExpansionPanel";
import DetailGroupExpansionPanel from "@/components/detailGroup/DetailGroupExpansionPanel";
import AddressPropertyForm from "@/components/property/AddressPropertyForm.vue";

export default {
  components: {
    DialogHeader,
    ImageGallery,
    VideoGallery,
    AddressPropertyForm,
    DetailGroupExpansionPanel,
    PropertyManagementExpansionPanel,
  },
  props: {
    showForm: {
      type: Boolean,
      default: false,
    },
    selectedProperty: {},
  },
  watch: {
    showForm() {
      this.reloadGallery += 1;
      // if (this.selectedProperty.id) {
      //   this.loadAllMedia(this.selectedProperty.id);
      // }
      this.mountOwnerList();

      this.selectedProperty.property_type = this.selectedProperty.property_type
        ? this.selectedProperty.property_type
        : "HOUSE";
    },
  },
  computed: {
    ...mapGetters(["userId", "isMainAdmin","userToken"])
  },
  data() {
    return {
      selectedPropertyOwnerId: null,
      propertyOwnerList: [],
      addedManagementGroups: [],
      reloadGallery: 0,
      mustReloadWhenClose: false,
      savePropertyDialogToggle: false,
      types: [
        { label: "House", value: "HOUSE" },
        { label: "Apartment", value: "APARTMENT" },
        { label: "Camping", value: "CAMPING" },
        { label: "Garage", value: "GARAGE" },
        { label: "Event", value: "EVENT" },
        { label: "Warehouse", value: "WAREHOUSE" },
      ],
      valid: true,
      property_medias: [],
      videos: [],
      rules: {
        required: (value) => !!value || "Required field",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        min: (v) => (v && v.length >= 6) || "Minimum 6 characters",
        matchPassword: (v) =>
          (v && v === this.register.password) ||
          "Password and confirm does not match",
        matchRePassword: (v) =>
          (v && v === this.repassword) || "Password and confirm does not match",
      },
      // upload
      displayPicture: null,
      image_upload: null,
    };
  },
  methods: {
    mountOwnerList(){
      this.propertyOwnerList = []
      // Set owner according to saved in database:
      if (this.selectedProperty != null) {
        this.selectedPropertyOwnerId = this.selectedProperty.id_company
      }


      const avoided_license_type = 'FREE_ACCOUNT';
      const token_parsed = parseJwt(this.userToken);

      if(token_parsed.license_type!=avoided_license_type){
        this.propertyOwnerList.push({'company_id':token_parsed.company_id, 'company_name': token_parsed.company_name, 'license_type': token_parsed.license_type, 'license_exp': token_parsed.license_exp});
      }

      if(token_parsed.extra_licenses) {
        const filtered_list = token_parsed.extra_licenses.filter((license)=>{
          return license.license_type != avoided_license_type
        })
        if (!this.propertyOwnerList.includes(filtered_list)) {
          this.propertyOwnerList.push(filtered_list);
        }
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    updateManagementGroup(addedGroupList) {
      this.selectedProperty.management_group_list = addedGroupList.map(group => group.id)
    },
    save(closeModal = true) {
      this.validate();

      if (this.valid) {
        this.selectedProperty.id_company = this.selectedPropertyOwnerId
        if (this.selectedProperty.id) {
          PropertyService.editProperty(
            this.selectedProperty.id,
            this.selectedProperty
          )
            .then(
              () => {
                this.resetValidation();
                this.$emit("saved");
              },
              (error) => {
                this.$store.dispatch("openSnackbar", {
                  message: "Error: " + error.response.data.message,
                });
              }
            )
            .finally(() => {
              this.$store.dispatch("subtractLoading");
            });
        } else {
          //SAVE
          PropertyService.addProperty(this.selectedProperty)
            .then(
              (response) => {
                if (closeModal) {
                  this.resetValidation();
                  this.$emit("saved");
                } else {
                  this.$store.dispatch("openSnackbar", {
                    message:
                      "Property saved succesfully. You can now add details, pictures and videos.",
                    color: "success",
                  });
                  this.selectedProperty = response.data;
                  //Will reload parent's property list when closing this form since a new property was added.
                  this.mustReloadWhenClose = true;
                }
              },
              (error) => {
                this.$store.dispatch("openSnackbar", {
                  message: "Error: " + error.response.data.message,
                });
              }
            )
            .finally(() => {
              this.$store.dispatch("subtractLoading");
            });
        }
      }
    },
    close() {
      this.resetValidation();
      this.reloadGallery += 1;
      this.videos = [];
      this.displayPicture = null;
      this.image_upload = null;
      //Make a copy to send to parent
      var willReload = this.mustReloadWhenClose;
      //reset value, so that next time form is open and closed it won't reload if not necessary
      this.mustReloadWhenClose = false;
      this.$emit("closed", willReload);
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
