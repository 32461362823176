export const userToken = (state) => {
  return state.userToken;
};

export const userName = (state) => {
  return state.userName;
};

export const isMainAdmin = (state) => {
  return state.isMainAdmin;
};

export const companyName = (state) => {
  return state.companyName;
};

export const companyId = (state) => {
  return state.companyId;
};

export const userId = (state) => {
  return state.userId;
};

export const prfImg = (state) => {
  return state.prfImg;
};

export const prfImgFile = (state) => {
  return state.prfImgFile;
};

export const userEmail = (state) => {
  return state.userEmail;
};

export const validationEmail = (state) => {
  return state.validationEmail;
};

export const validationUserId = (state) => {
  return state.validationUserId;
};

export const companies = (state) => {
  return state.companies;
};

export const tempToken = (state) => {
  return state.tempToken;
};
