<template>
  <div>
    <v-layout>
      <v-flex>
        <v-text-field
          outlined
          prepend-inner-icon="mdi-map-marker-radius"
          label="Choose your prefered region"
          color="primary"
          :append-icon="'travel_explore'"
          @click:append="searchAddres"
          @keyup.enter.native="eventAddressChange"
          @keyup.tab.native="eventAddressChange"
          v-model="selectedModelToAddress.free_form_address"
          :rules="[rules.required]"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxlength="255"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <!-- {{this.selectedProperty.latitude}}
              {{this.selectedProperty.longitude}} -->
    <v-layout>
      <v-flex md12 pb-2>
        <v-card
          style="margin-bottom: auto"
          id="myMap"
          min-height="500px"
          :height="
            this.selectedModelToAddress.latitude &&
            this.selectedModelToAddress.longitude
              ? '300px;'
              : '0px'
          "
        >
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";
import MediaService from "@/services/mediaService";

const loader = new Loader({
  apiKey: "AIzaSyCVubNG7qZPsNU5YKNuALWF7dE5CUfDvC8",
  version: "weekly",
  libraries: ["places"],
});

export default {
  props: {
    selectedModelToAddress: {},
    propertyList: {},
  },

  data: () => ({
    manualLocation: false,
    allAddressMarkersList:[],
    zoom: 4,
    rules: {
      required: (value) => !!value || "Required field",
    },
  }),
  watch: {
    propertyList: {
      immediate: true,
      handler() {
        // console.log("---- HANDLER PROPERTY LIST.....");
        
        if (!this.propertyList) {
          return;
        }

        if (!this.map || !this.currrentGoogleInstance) {
          loader.load().then((google) => {
            // init
            this.currrentGoogleInstance = google;
            const myLatlng = { lat: -25.363, lng: 131.044 };
            this.map = new this.currrentGoogleInstance.maps.Map(document.getElementById("myMap"), {
              zoom: this.zoom,
              center: myLatlng,
            });

            this.addListenerClick();
            this.addListenerZoom();

            this.searchAddressRunner();
            this.loadAllAddressPoints();
          });
        } else {
          this.searchAddressRunner();
          this.loadAllAddressPoints();
        }
      },
    },
    selectedModelToAddress: {
      immediate: true,
      handler() {
        this.manualLocation = false;

        if (
          this.selectedModelToAddress.latitude &&
          this.selectedModelToAddress.longitude
        ) {
          this.buildMap();
        } else if (this.selectedModelToAddress.free_form_address) {
          this.searchAddres();
        }
      },
    },
  },
  methods: {
    buildInfoWindowContent(currentProperty,location, media_link = null) {
      
      const contentString =
        '<div id="content">' +
        '<h3 class=" firstHeading primary" style="color:white; margin-bottom:10px;" id="firstHeading" >' +
        currentProperty.free_form_address +
        "</h3>" +
        '<div id="bodyContent" >' +
        '<div >' +
        (media_link ?'<img src="'+media_link+'" style="width:100px; float: left; margin-right:10px;" alt="" />': '') +
        (currentProperty.address_detail
          ? " <b>Apt, Suite, Number, c/o :</b> " + currentProperty.address_detail + "<br/>"
          : "") +
        (currentProperty.city ? " <b>City:</b> " + currentProperty.city + "<br/>" : "") +
        (currentProperty.postal_code
          ? "<b>Postal code:</b> " + currentProperty.postal_code + "<br/>"
          : "") +
        (currentProperty.state_province
          ? "<b>State/Province:</b> " + currentProperty.state_province + "<br/>"
          : "") +
        (currentProperty.country ? "<b>Country:</b> " + currentProperty.country + "<br/>" : "") +
        "</div>" +
        "</div>" +
        "<p></p>"+
        '<p></p><b>You may want:</b><p> <a onclick="document.getElementById(\'selectedPropertyIdFromMap\').value = \''+currentProperty.id+'\'; document.getElementById(\'propertyFormBtnHome\').click();" >' +
        "View this property</a> " +
        " or " +
        ' <a href="/rental?propertyId='+currentProperty.id+'">' +
        "Generate a new rental</a> " +
        "</p>" +
        "</div>";

      // Create the initial InfoWindow.
      let infoWindow = new this.currrentGoogleInstance.maps.InfoWindow({
        content: contentString,
        position: location,
      });

      infoWindow.open(this.map);
      this.$emit("infoWindowSelected", infoWindow);
    },
    loadAllAddressPoints() {

      this.allAddressMarkersList.forEach(item => {item.setMap(null);});
      this.allAddressMarkersList = [];

      this.propertyList.forEach((item) => {
        let location = { lat: item.latitude, lng: item.longitude };
        let label = item.free_form_address;
        let marker = new this.currrentGoogleInstance.maps.Marker({
          position: location,
          title: "Click to open details.",
          // label: results[0].name,
          // icon: markerImage,
          label: label,
        });
        marker.setMap(this.map);

        marker.addListener("click", () => {

          this.$emit("markerSelected", marker);
          
          if(item.main_id_property_media){

            MediaService.getProtectedImageLink('property',
            item.main_id_property_media,
            item.main_property_media_extension
          )
            .then(
              (response) => {
                let media_link = response.data;

                this.buildInfoWindowContent(item,location, media_link)
                
              },
              (error) => {
                console.log(error);
              }
            )
            .finally(() => {
              this.$store.dispatch("subtractLoading");
              this.reloadSlider += 1;
            });

          }else{
            this.buildInfoWindowContent(item,location)
          }

        });

        this.allAddressMarkersList.push(marker);
        
      });
    },
    eventAddressChange() {
      if (!this.selectedModelToAddress.free_form_address) {
        return;
      }

      if (this.manualLocation) {
        this.manualUpdateLocationLabelPoint();
      } else {
        this.searchAddres();
      }
    },
    // MAPS
    manualUpdateLocationLabelPoint() {
      this.currentMarker.setMap(null);
      this.currentMarker = new this.currrentGoogleInstance.maps.Marker({
        position: this.currentMarker.position,
        title: "Property Location!",
        label: this.selectedModelToAddress.free_form_address,
      });
      this.currentMarker.setMap(this.map);
    },
    searchAddres() {
      console.log("loading..." + this.selectedModelToAddress.free_form_address);
      if (!this.selectedModelToAddress.free_form_address) {
        return;
      }

      if (!this.map || !this.currrentGoogleInstance) {
        loader.load().then((google) => {
          // init
          this.currrentGoogleInstance = google;
          const myLatlng = { lat: -25.363, lng: 131.044 };
          this.map = new google.maps.Map(document.getElementById("myMap"), {
            zoom: this.zoom,
            center: myLatlng,
          });

          this.currentMarker = new google.maps.Marker({
            position: myLatlng,
            //map,
            title: "Property Location!",
            label: this.selectedModelToAddress.free_form_address,
          });

          this.addListenerClick();
          this.addListenerZoom();

          this.searchAddressRunner();
        });
      } else {
        this.searchAddressRunner();
      }
    },

    searchAddressRunner() {
      const request = {
        query: this.selectedModelToAddress.free_form_address,
        fields: ["place_id", "name", "geometry"],
      };

      let service = new this.currrentGoogleInstance.maps.places.PlacesService(
        this.map
      );

      service.findPlaceFromQuery(request, (results, status) => {
        if (
          status ===
            this.currrentGoogleInstance.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          // console.log(results);
          // console.log(results[0].place_id);

          // this.currentMarker.setMap(null);

          // this.currentMarker = new this.currrentGoogleInstance.maps.Marker({
          //   position: results[0].geometry.location,
          //   title: "Property Location!",
          //   label: results[0].name,
          // });

          // To add the marker to the map, call setMap();
          // this.currentMarker.setMap(this.map);

          this.map.setCenter(results[0].geometry.location);
          this.map.panTo(results[0].geometry.location);

          // this.setLocation(
          //   results[0].geometry.location.lat(),
          //   results[0].geometry.location.lng()
          // );
        }
      });
    },
    setLocation(lat, lng) {
      this.selectedModelToAddress.latitude = lat;
      this.selectedModelToAddress.longitude = lng;
      this.$forceUpdate();
    },
    buildMap() {
      loader
        .load()
        .then((google) => {
          // init
          this.currrentGoogleInstance = google;
          const myLatlng = {
            lat: this.selectedModelToAddress.latitude,
            lng: this.selectedModelToAddress.longitude,
          };
          this.map = new this.currrentGoogleInstance.maps.Map(
            document.getElementById("myMap"),
            {
              zoom: this.zoom,
              center: myLatlng,
            }
          );

          this.currentMarker = new google.maps.Marker({
            position: myLatlng,
            map: this.map,
            title: "Property Location!",
            label: this.selectedModelToAddress.free_form_address,
          });

          // map.addListener("center_changed", () => {
          //   console.log("click3");
          //   // 3 seconds after the center of the map has changed, pan back to the
          //   // marker.
          //   window.setTimeout(() => {
          //     map.panTo(marker.getPosition());
          //   }, 3000);
          // });

          // marker.addListener("click", () => {
          //   console.log("click");
          //   map.setZoom(8);
          //   map.setCenter(marker.getPosition());
          // });

          // Create the initial InfoWindow.
          // let infoWindow = new google.maps.InfoWindow({
          //   content: "Click the map to get Lat/Lng!",
          //   position: myLatlng,
          // });

          // infoWindow.open(map);

          // Configure the click listener.
          this.addListenerClick();
          this.addListenerZoom();

          //end
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addListenerClick() {
      // Configure the click listener.
      this.map.addListener("click", (mapsMouseEvent) => {
        if (!this.manualLocation) {
          return;
        }

        let currentLabel = this.currentMarker.label;
        this.setLocation(
          mapsMouseEvent.latLng.lat(),
          mapsMouseEvent.latLng.lng()
        );

        this.currentMarker.setMap(null);
        this.currentMarker = new this.currrentGoogleInstance.maps.Marker({
          position: mapsMouseEvent.latLng,
          title: "Property Location!",
          label: currentLabel,
        });

        // To add the marker to the map, call setMap();
        this.currentMarker.setMap(this.map);

        // map.setCenter(mapsMouseEvent.latLng);
        window.setTimeout(() => {
          this.map.panTo(this.currentMarker.getPosition());
        }, 500);
      });
    },
    addListenerZoom() {
      this.map.addListener("zoom_changed", () => {
        console.log("zoom changed" + this.map.getZoom());
      });
    },
  },
};
</script>