<template>
  <div row class="dialog-header-container">
    <div row class="d-flex justify-space-between">
      <div>{{title}}</div>
      <v-spacer></v-spacer>
      <v-btn icon @click="close" style="    margin-top: -6px;">
        <v-icon class="white--text">close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: null,
  },
  methods: {
    close() {
      this.$emit("closed");
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
