<template>
  <div>
    <v-dialog
      v-model="selectPropertyToggle"
      scrollable
      persistent
      max-width="1000"
    >
      <v-card>
        <dialog-header
          :title="'Select Property'"
          @closed="close"
        ></dialog-header>

        <v-card-title>
          <!-- <v-spacer></v-spacer> -->
          <v-container>
            <v-row>
              <v-text-field
                v-model="searchText"
                @keyup.enter.native="search"
                @keyup.tab.native="search"
                label="Type text to search for properties..."
                single-line
                hide-details
              ></v-text-field>
              <v-btn
                @click="search"
                class="ma-2"
                color="primary"
                outlined
                width="100"
              >
                <v-icon style="margin-right: 5px"> mdi-magnify </v-icon
                >Search</v-btn
              >
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="propertyHeaders"
            :items="properties"
            item-key="name"
            :loading="loading"
            @click:row="clickedItem"
            hide-default-footer
            :items-per-page="15"
          >
          </v-data-table>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <!-- Pagination area -->
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-pagination
                  v-model="pagination.page"
                  @input="listPropertiesFromCompany()"
                  class="my-4"
                  :length="pagination.maxPage"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DialogHeader from "@/components/shared/DialogHeader";
import PropertyService from "@/services/propertyService";
export default {
  components: {
    DialogHeader,
  },
  props: {
    company_id: String,
    selectPropertyToggle: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.listPropertiesFromCompany();
  },
  data() {
    return {
      loading: false,
      searchText: "",
      pagination: {
        page: 1,
        maxPage: 1,
      },
      propertyHeaders: [
        { text: "Name", align: "start", value: "label" },
        { text: "Address", value: "free_form_address" },
      ],
      properties: [],
    };
  },
  methods: {
    clickedItem(value) {
      this.$emit("selected", value);
    },
    close() {
      this.$emit("close");
    },
    search() {
      this.pagination.page = 1;
      this.listPropertiesFromCompany();
    },
    listPropertiesFromCompany() {
      this.loading = true;
      PropertyService.listPropertiesFromCompany(
        this.pagination.page,
        this.searchText
      )
        .then(
          (success) => {
            this.properties = success.data;
            this.pagination.maxPage = success.max_page;
          },
          (error) => {
            this.$store.dispatch("openSnackbar", {
              message: "Error: Please, contact support: " + error,
            });
          }
        )
        .finally(() => {
          this.$store.dispatch("subtractLoading");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>