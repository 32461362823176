import http from "@/http";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithCustomToken } from "firebase/auth";

class FirestoreService {
    constructor() {
        this.baseAuthUrl = 'auth'
        this.pageSize = 10
    }

    requestFirestoreToken() {
        return http.get(`${this.baseAuthUrl}/login/communication`)
            .then(({ data }) => {
                return data.data.token;
            })
    }

    initializeFirestoreApp() {
        const firebaseConfig = {
            apiKey: "AIzaSyBbDhZd28T_sVJ43VvgjJljggFy4y8r-eI",
            authDomain: "salvedomi-a289a.firebaseapp.com",
            projectId: "salvedomi-a289a",
            storageBucket: "salvedomi-a289a.appspot.com",
            messagingSenderId: "292608231936",
            appId: "1:292608231936:web:31d133628585db754626e5"
        };

        const app = initializeApp(firebaseConfig);

        return this.requestFirestoreToken().then((token)=>{
            //   var token = "eyJhbGciOiAiUlMyNTYiLCAidHlwIjogIkpXVCIsICJraWQiOiAiOWNiYmE4NzZlMDRlNjdlMjQ4ZThkYWU1MmIxYjU0NzRkM2M1ZjUwMSJ9.eyJpc3MiOiAiZmlyZWJhc2UtYWRtaW5zZGstYmM3ODhAc2FsdmVkb21pLWEyODlhLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwgInN1YiI6ICJmaXJlYmFzZS1hZG1pbnNkay1iYzc4OEBzYWx2ZWRvbWktYTI4OWEuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCAiYXVkIjogImh0dHBzOi8vaWRlbnRpdHl0b29sa2l0Lmdvb2dsZWFwaXMuY29tL2dvb2dsZS5pZGVudGl0eS5pZGVudGl0eXRvb2xraXQudjEuSWRlbnRpdHlUb29sa2l0IiwgInVpZCI6ICIxYmE2Mzg0Mi1jN2YwLTQwM2UtOTc4Yy1kZDQzZTRhN2YxOTciLCAiaWF0IjogMTY1NDgwMTkzMSwgImV4cCI6IDE2NTQ4MDU1MzEsICJjbGFpbXMiOiB7ImNvbXBhbnlfaWQiOiAiZWQwZWY4ZjUtY2VlYy00MGZiLWI3OWQtNWZkODU0MmE2OGI2IiwgInVzZXJfaWQiOiAiMWJhNjM4NDItYzdmMC00MDNlLTk3OGMtZGQ0M2U0YTdmMTk3In19.4R0HkjljnIlFVDeekgMYDTTUaQwwfmOVurpQ4gW4DaFEXUmznSW_rQ1LHhWuNFqk44YlC_8ydauhj1Ys4rgns0A58aYKmGlL7-_YqgTlPwdgvg2jUsDI9IlNK_zxXBgDsX4q9JkAePs-DuM1h5Xho-_0IeUh8wv84m0prD5LR-vGtjeVypLNfAvMZ98sEZ6KAIpVqTzy-5WEd89eRZL2k_S62y2FprLatwLv3Xxxcz6sWOE7OFxHYGVWYPyyLmoXCu1yLQG4G7earJ5CSK27xi7oXESplKaxuca12kfZ4uQr8CmXKHWZpW8LfkRjox6DeOar1ChfNIHmGFyYcAWOig";
            const auth = getAuth(app);
            return signInWithCustomToken(auth, token)
                .then((userCredential) => {
                    // const user = userCredential.user;
                    // // console.log('-----USER');
                    // console.log(user);

                    const db = getFirestore(auth.app);
                    // console.log(db);
                    
                    return {
                        db :db,
                        credentials :userCredential,
                    };
                });

        })
            .catch((error) => {
                console.log(error);
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // ...
            });

    }

}

export default new FirestoreService()