<template>
  <div>
    <v-row>
      <v-container style="margin-top: 20px">
        <v-form ref="form">
          <v-file-input
            v-model="file"
            filled
            small-chips
            prepend-icon="mdi-video"
            accept=".mp4"
            label="Click here to select a video to upload"
          ></v-file-input>
        </v-form>
      </v-container>
    </v-row>
    <v-row class="fill-height" align-content="center" justify="center">
      <v-btn
        @click="uploadImages"
        class="ma-2"
        color="primary"
        outlined
        width="200"
        :disabled="!this.file || this.isUploading"
      >
        <v-icon style="margin-right: 5px"> mdi-cloud-upload </v-icon>Upload
        Video</v-btn
      >
    </v-row>
    <v-row v-show="isUploading">
      <v-container style="height: 100px">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col
            class="text-subtitle-1 text-center"
            style="color: grey"
            cols="12"
          >
            Uploading file {{ uploadingFileName }}...
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="blue accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </div>
</template>

<script>
import VideoService from "@/services/videoService";

export default {
  props: {
    selectedParent: null,
    endpoint: null,
  },
  data() {
    return {
      file: null,
      isUploading: false,
      uploadingFileName: null,
      deleteToggle: false,
      imageIndex: 0,
      reloadSlider: 0,
    };
  },
  methods: {
    uploadImages() {
      if (this.selectedParent.id) {
        console.log(this.file)
        if (this.file) {
          this.isUploading = true;
          let formData = new FormData();
          formData.append("media", this.file, this.file.name);

          VideoService.uploadVideo(this.endpoint, formData, this.selectedParent.id)
            .then(
              () => {
                this.$emit("videoUploaded")
                this.$store.dispatch("openSnackbar", {
                  message:
                    "Video uploaded successfully. Video will be available once processing is finished.",
                  color: "success",
                });
              },
              () => {
                console.log("Error");
              }
            )
            .finally(() => {
              this.isUploading = false;
              this.file = null;
              this.$store.dispatch("subtractLoading");
            });
        } else {
          this.$store.dispatch("openSnackbar", {
            message: "Please, select a video to upload.",
          });
        }
      } else {
        this.$emit("displaySavePropertyDialog");
      }
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
