<template>
  <div>
    <!-- PROPERTY SELECTOR  -->
    
      <property-selector
        :selectPropertyToggle="selectPropertyToggle"
        @close="closeSelectProperty"
        @selected="propertySelected"
      ></property-selector>
    
    <!-- END PROPERTY SELECTOR  -->
    <v-dialog v-model="showForm" persistent scrollable max-width="1000px">
      <v-card>
        <dialog-header :title="'Manage Rental'" @closed="close"></dialog-header>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout>
              <v-container>
                <v-layout>
                  <v-flex md12 sd12>
                    <v-text-field
                      class="pr-2"
                      outlined
                      v-if="selectedEvent.id"
                      label="Property"
                      :value="(this.selectedProperty.label ? this.selectedProperty.label : this.selectedProperty.free_form_address)"
                      :rules="propertyRules"
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-else
                      class="pr-2"
                      outlined
                      label="Select property"
                      :value="(this.selectedProperty.label ? this.selectedProperty.label : this.selectedProperty.free_form_address)"
                      :rules="propertyRules"
                      hint="Please, click on the button below and select a property."
                      readonly
                      color:append="primary"
                    ></v-text-field>
                  </v-flex>
                  <v-btn v-if="!this.selectedEvent.id"
                    style="height: 4em"
                    color="primary"
                    @click="openSelectProperty()"
                  >
                    <v-icon right> home_work </v-icon>
                    <v-icon right> search </v-icon>
                  </v-btn>
                </v-layout>
                <!-- TIMEZONE
                  <v-text-field :value="selectedProperty.time_zone_id" ></v-text-field>
                  <v-text-field :value="time_zone_id" ></v-text-field> -->
                <h5 v-if="this.selectedProperty.time_zone_id" outlined class="mb-2">
                  {{ this.selectedProperty.time_zone_name }} ({{ this.selectedProperty.time_zone_id }})
                </h5>
                <div v-if="selectedProperty.id">
                  <v-layout>
                    <v-flex md6>
                      <vc-date-picker
                        v-model="selectedEvent.start_datetime"
                        mode="dateTime"
                        :timezone="this.selectedProperty.time_zone_id"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <v-text-field
                            class="pr-2"
                            outlined
                            :value="inputValue"
                            v-on="inputEvents"
                            label="Check-in Date"
                            prepend-inner-icon="mdi-calendar"
                            :rules="[rules.required]"
                            required
                            readonly
                          ></v-text-field>
                        </template>
                      </vc-date-picker>
                    </v-flex>
                    <v-flex md6>
                      <vc-date-picker
                        v-model="selectedEvent.end_datetime"
                        mode="dateTime"
                        :timezone="this.selectedProperty.time_zone_id"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <v-text-field
                            class="pr-2"
                            outlined
                            :value="inputValue"
                            v-on="inputEvents"
                            label="Check-out Date"
                            prepend-inner-icon="mdi-calendar"
                            :rules="[rules.required]"
                            required
                            readonly
                          ></v-text-field>
                        </template>
                      </vc-date-picker>
                    </v-flex>
                  </v-layout>

                  <v-layout>
                    <v-flex md6>
                      <v-text-field
                        class="pr-2"
                        outlined
                        prepend-inner-icon="person"
                        v-model="selectedEvent.main_guest_name"
                        label="Main Guest Name"
                        required
                        :rules="[rules.required]"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="255"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        class="pr-2"
                        outlined
                        prepend-inner-icon="mail"
                        v-model="selectedEvent.main_guest_email"
                        label="Main Guest Email"
                        required
                        :rules="[rules.required, rules.email]"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="255"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout>
                    <v-flex md12>
                      <v-text-field
                        class="pr-2"
                        outlined
                        prepend-inner-icon="bookmarks"
                        label="Label (optional)"
                        v-model="selectedEvent.label"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="255"
                        counter="255"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <!-- <v-layout>
                  <v-flex md12>
                    <v-textarea
                      class="pr-2"
                      outlined
                      prepend-inner-icon="assignment"
                      v-model="selectedEvent.description"
                      auto-grow
                      label="Description"
                      rows="2"
                      :rules="[rules.required]"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength="500"
                      counter="500"
                    ></v-textarea>
                  </v-flex>
                  </v-layout> -->
                  <v-layout>
                    <v-flex md6>
                      <vc-date-picker
                        v-model="selectedEvent.available_since"
                        mode="dateTime"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <v-text-field
                            class="pr-2"
                            outlined
                            :value="inputValue"
                            v-on="inputEvents"
                            label="Available since"
                            prepend-inner-icon="mdi-calendar"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </template>
                      </vc-date-picker>
                    </v-flex>
                  </v-layout>
                </div>
              </v-container>
              <!-- <small>*indicates required field</small> -->
            </v-layout>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DialogHeader from "@/components/shared/DialogHeader";
import EventService from "@/services/eventService";
import PropertyService from "@/services/propertyService";
import PropertySelector from "@/components/property/PropertySelector";
import moment from "moment";
import "moment-timezone";

export default {
  components: {
    DialogHeader,
    PropertySelector,
  },
  props: {
    showForm: {
      type: Boolean,
      default: false,
    },
    selectedEvent: {},
    preSelectedProperty: {},
  },

  watch: {
    selectedEvent: {
      immediate: true,
      handler(newValue) {
        this.selectedEvent = newValue;
        this.selectedProperty = newValue.property;
        if(this.selectedProperty){
          moment.tz.setDefault(this.selectedProperty.time_zone_id);
        }
      },
    },
    preSelectedProperty: {
      immediate: true,
      handler(newValue) {
        this.selectedProperty = newValue;
        this.time_zone_id = this.selectedProperty.time_zone_id;
        moment.tz.setDefault(this.selectedProperty.time_zone_id);
      },
    },
    showForm() {
      if (this.selectedEvent.id) {
        this.loadPropertyLabel(this.selectedEvent.id_property);
        moment.tz.setDefault(this.selectedProperty.time_zone_id);
        this.selectedEvent.available_since = new Date();
      } else {
        this.setTimezoneAndStartDates();
      }
    },
  },
  data() {
    return {
      time_zone_id: "aaaa",
      selectPropertyToggle: false,
      selectedProperty: {
        id: "",
        label: "",
        time_zone_id: null,
      },
      defaultItem: {
        id: "",
        id_property: "",
        main_guest_name: "",
        main_guest_email: "",
        label: "",
        description: "",
        start_datetime: "",
        end_datetime: "",
        available_since: new Date(),
        max_members: 1,
        url_rental_platform: "",
      },
      valid: true,
      rules: {
        required: (value) => !!value || "Required field",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      propertyRules: [
        (v) =>
          !!v ||
          "Property is required. Please click on the button below and select a property.",
      ],
    };
  },
  methods: {
    setTimezoneAndStartDates() {
      moment.tz.setDefault(this.selectedProperty.time_zone_id);
      this.selectedEvent.start_datetime = moment()
        .set({ hour: 16, minutes: 0, seconds: 0 })
        .format();
      this.selectedEvent.end_datetime = moment()
        .set({ hour: 10, minutes: 0, seconds: 0 })
        .add(1, "days")
        .format();
      this.selectedEvent.available_since = new Date();
    },
    allowedDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    functionEvents(date) {
      const [, , day] = date.split("-");
      // if ([12, 17, 28].includes(parseInt(day, 10))) return true
      if ([1, 2, 3, 19, 20, 21, 22].includes(parseInt(day, 10))) return ["red"];
      // if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', '#00f']
      return false;
    },
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    propertySelected(value) {
      this.selectPropertyToggle = false;
      this.selectedProperty = value;
      this.setTimezoneAndStartDates();
    },
    save() {
      
      this.validate();
      if (this.valid) {
        this.selectedEvent.id_property = this.selectedProperty.id;
        this.edit = false;

        if (this.selectedEvent.id) {
          //UPDATE
          EventService.editEvent(this.selectedEvent.id, this.selectedEvent)
            .then(
              () => {
                this.$emit("saved");
              },
              (error) => {
                this.$store.dispatch("openSnackbar", {
                  message: "Error: " + error.response.data.message,
                });
              }
            )
            .finally(() => {
              this.$store.dispatch("subtractLoading");
            });
        } else {
          //SAVE
          EventService.addEvent(this.selectedEvent)
            .then(
              () => {
                this.$emit("saved");
              },
              (error) => {
                this.$store.dispatch("openSnackbar", {
                  message: "Error: " + error.response.data.message,
                });
              }
            )
            .finally(() => {
              this.$store.dispatch("subtractLoading");
            });
        }
      }
    },
    close() {
      this.resetValidation();
      this.$emit("closed");
    },
    openSelectProperty() {
      this.selectPropertyToggle = true;
    },
    closeSelectProperty() {
      this.selectPropertyToggle = false;
    },
    loadPropertyLabel(id) {
      if (this.selectedEvent.id) {
        PropertyService.getPropertyById(id)
          .then(
            (success) => {
              this.selectedProperty = success.data;
            },
            (error) => {
              this.$store.dispatch("openSnackbar", {
                message: "Error: Please, contact support: " + error,
              });
            }
          )
          .finally(() => {
            this.$store.dispatch("subtractLoading");
          });
      }
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
