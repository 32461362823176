<template>
  <div>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Properties</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white primary--text" @click="showForm = true">
          <v-icon> home_work </v-icon>
          <v-icon> add </v-icon>
          New Property
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <!-- <v-spacer></v-spacer> -->
        <v-container>
          <v-row>
            <v-text-field
              v-model="searchText"
              @keyup.enter.native="search"
              @keyup.tab.native="search"
              label="Type text to search for properties..."
              single-line
              hide-details
            ></v-text-field>
            <v-btn
              @click="search"
              class="ma-2"
              color="primary"
              outlined
              width="100"
            >
              <v-icon style="margin-right: 5px"> mdi-magnify </v-icon
              >Search</v-btn
            >
          </v-row>
        </v-container>
      </v-card-title>

      <v-data-table
        :headers="propertyHeaders"
        :items="properties"
        item-key="name"
        hide-default-footer
        :items-per-page="15"
      >
        <template v-slot:item.start_datetime="{ item }">
          <span>{{ new Date(item.start_datetime).toLocaleString() }}</span>
        </template>
        <template v-slot:item.end_datetime="{ item }">
          <span>{{ new Date(item.end_datetime).toLocaleString() }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon @click="deleteItem(item)">mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Pagination area -->
    <div class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.page"
                @input="listPropertiesFromCompany()"
                class="my-4"
                :length="pagination.maxPage"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- DELETE DIALOG -->
    <v-row justify="center">
      <v-dialog
        v-model="deleteItemToggle"
        persistent
        max-width="450"
        v-if="selectedProperty"
      >
        <v-card>
          <v-card-title class="text-h5"> Delete Property </v-card-title>
          <v-card-text
            >Are you sure you want to delete the following property? <br />
            <br /><strong>Property: </strong>{{ selectedProperty.label
            }}<br /><strong>Address: </strong
            >{{ selectedProperty.free_form_address }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="(deleteItemToggle = false), resetSelectedProperty()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="
                (deleteItemToggle = false),
                  deleteItemConfirmed(selectedProperty.id)
              "
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- PROPERTY FORM' -->
    <v-row justify="center">
      <property-form
        @closed="formClosed"
        @saved="formSaved"
        :showForm="this.showForm"
        :selectedProperty="this.selectedProperty"
      ></property-form>
    </v-row>
  </div>
</template>

<script>
import PropertyService from "@/services/propertyService";
import PropertyForm from "@/components/property/PropertyForm";

export default {
  components: {
    PropertyForm,
  },
  async mounted() {
    if (this.$route.query.id) {
      try {
        let result = await PropertyService.getPropertyById(
          this.$route.query.id
        );
        this.editItem(result.data);
      } catch (error) {
        let msg =
          error.response.status == 422
            ? 'Field "' +
              error.response.data.detail[0].loc[1] +
              '" ' +
              error.response.data.detail[0].msg
            : error.response.data.message;
        this.$store.dispatch("openSnackbar", {
          message: "Error: " + msg,
        });
      } finally {
        this.$store.dispatch("subtractLoading");
      }
    }
  },
  created() {
    this.resetSelectedProperty();
    this.listPropertiesFromCompany();
  },
  data() {
    return {
      searchText: "",
      pagination: {
        page: 1,
        maxPage: 1,
      },
      showForm: false,
      dialog: false,
      deleteItemToggle: false,
      defaultItem: {
        id: "",
        label: "",
        description: "",
        property_type: "",
        url_rental_platform: "",
      },
      propertyHeaders: [
        { text: "Label", align: "start", value: "label" },
        { text: "Type", align: "start", value: "property_type" },
        { text: "Address", value: "free_form_address" },
        { text: "", value: "actions" },
      ],
      properties: [],
    };
  },
  methods: {
    editItem(item) {
      this.selectedProperty = Object.assign({}, item);
      this.showForm = true;
    },
    formSaved() {
      this.showForm = false;
      this.resetSelectedProperty();
      this.listPropertiesFromCompany();
    },
    formClosed(mustReloadList = false) {
      this.showForm = false;
      this.resetSelectedProperty();
      if (mustReloadList) {
        this.listPropertiesFromCompany();
      }
      // this.$store.dispatch("subtractLoading");
    },
    deleteItem(item) {
      this.deleteItemToggle = true;
      this.selectedProperty = item;
    },
    deleteItemConfirmed(id) {
      PropertyService.deleteProperty(id)
        .then(
          () => {
            this.listPropertiesFromCompany(1);
          },
          (error) => {
            console.log(error.response.data.detail);
            this.$store.dispatch("openSnackbar", {
              message: "Error: " + error.response.data.message,
            });
          }
        )
        .finally(() => {
          this.$store.dispatch("subtractLoading");
          this.resetSelectedProperty();
        });
    },
    resetSelectedProperty() {
      this.selectedProperty = Object.assign({}, this.defaultItem);
    },
    search() {
      
      this.pagination.page = 1;
      this.listPropertiesFromCompany();
    },
    listPropertiesFromCompany() {
      PropertyService.listPropertiesFromCompany(
        this.pagination.page,
        this.searchText
      )
        .then(
          (success) => {
            this.properties = success.data;
            this.pagination.maxPage = success.max_page;
          },
          (error) => {
            this.$store.dispatch("openSnackbar", {
              message: "Error: Please, contact support: " + error,
            });
          }
        )
        .finally(() => {
          this.$store.dispatch("subtractLoading");
        });
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';

.search-warning {
  margin-top: 10px;
  width: 100%;
  height: 50px;
}

</style>
