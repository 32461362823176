<template>
  <v-container>
    <!-- <div class="page-header">Welcome home...</div> -->
    <!-- <v-divider class="pa-2 mb-2"></v-divider> -->
    <v-row>
      <v-flex md4 style="padding: 10px;">
        <v-card style="height: 100%;" class="mx-auto" color="primary lighten-1" dark
          img="https://images.unsplash.com/photo-1551524164-687a55dd1126?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dmFjYXRpb24lMjBob21lfGVufDB8fDB8fA%3D%3D&w=1000&q=80">
          <v-card-title style="justify-content: right">
            <v-icon large right color="primary lighten-1"> loyalty </v-icon>
            <!-- <span class="text-h6 font-weight-light">12 rentals in progress</span> -->
          </v-card-title>

          <!-- <v-card-text class="text-h5 font-weight-bold">
            <v-row
              align="center"
              justify="end"
              style="color: white; background-color: rgba(0, 0, 0, 0.3)"
            >
              {{ total_active_properties }} destination
            </v-row>
          </v-card-text> -->

          <v-card-actions>
            <v-list-item class="grow">
              <v-row align="center" justify="end">
                <span class="subheading mr-2">
                  <v-icon> mdi-home </v-icon>
                  {{ active_properties_by_type.HOUSE }}
                </span>
                <!-- <v-spacer /> -->
                <span class="subheading mr-2">
                  <v-icon> apartment </v-icon>
                  {{ active_properties_by_type.APARTMENT }}
                </span>
                <!-- <v-spacer /> -->
                <!-- <span class="subheading mr-2">
                  <v-icon> cabin </v-icon>
                  {{ active_properties_by_type.CAMPING }}
                </span>
                <v-spacer /> -->
                <!-- <span class="subheading mr-2">
                  <v-icon> garage </v-icon>
                  {{ active_properties_by_type.GARAGE }}
                </span>
                <v-spacer /> -->
                <!-- <span class="subheading mr-2">
                  <v-icon> warehouse </v-icon>
                  {{ active_properties_by_type.WAREHOUSE }}
                </span>
                <v-spacer /> -->
                <!-- <span class="subheading mr-2">
                  <v-icon> nightlife </v-icon>
                  {{ active_properties_by_type.EVENT }}
                </span>
                <v-spacer /> -->
              </v-row>
            </v-list-item>
          </v-card-actions>

          <v-card-text class="text-h6">
            <v-row align="center" justify="center" style="color: white; background-color: rgba(0, 0, 0, 0.3)">
              <v-btn color="white" style="font-weight: 700;" text @click="goToProperties"> {{ property_avalable }} </v-btn>             
            </v-row>
          </v-card-text>

        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 10px;">
        <v-card class="mx-auto" color="primary lighten-1" style="height: 174px;" dark
          img="https://media.istockphoto.com/photos/wooden-pendant-of-a-house-and-key-background-of-sea-fir-tree-and-picture-id1304532341?b=1&k=20&m=1304532341&s=170667a&w=0&h=KpUDK5Qz95nUrt9yw-4GlW7iO6Tu4k7dA11Gtcf3IPs=">
          <v-card-title style="justify-content: right">
            <v-icon large left color="primary"> real_estate_agent </v-icon>
            <!-- <span class="text-h5 font-weight-bold">8</span> -->
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold" style="margin-top: 78px;">
            <v-row align="center" justify="center" style="color: white; background-color: rgba(0, 0, 0, 0.3)">
              <v-btn color="white" style="font-weight: 700;" text @click="goToRentals"> {{ rental_in_progress }} </v-btn>
              
            </v-row>
          </v-card-text>

        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 10px">
        <v-card class="mx-auto" color="primary lighten-1" style="height: 174px;" 
          img="https://t3.ftcdn.net/jpg/03/29/37/64/360_F_329376481_iNs6iGtCsqiz4B5EPj4BtrylC4JhUOj3.jpg">
          <v-card-title style="justify-content: right">
            <v-icon large left color="primary"> event_available </v-icon>
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold" style="margin-top: 78px;">
            <v-row align="center" justify="center" style="color: white; background-color: rgba(0, 0, 0, 0.3); height: 36px;">

              <v-flex md8>
                <v-btn color="white" style="font-weight: 700; float: right;" text @click="goToGuests"> {{ guest_in_action }} </v-btn>
              </v-flex>
              <v-flex md4>
                <v-badge dot overlap :color="this.notificationList && this.notificationList.length > 0 ? 'red' : ''
                " offset-x="18" offset-y="28" style="float: right;margin-right: 20px;">
                <v-btn id="notificationActionChatIcon" icon @click="openNotificationList = !openNotificationList">
                  <v-icon :color="this.isNotificationServiceConnected ? 'primary' : 'grey'">chat</v-icon>
                </v-btn>
              </v-badge>

              <navigation-drawer-notification @closed="() => {
                  this.openNotificationList = false;
                }
                " :openNotificationList="this.openNotificationList" />
              </v-flex>
            </v-row>
          </v-card-text>

        </v-card>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PropertyDashService from "@/services/propertyDashService";
import router from "@/router/index.js";
import NavigationDrawerNotification from "@/components/shared/NavigationDrawerNotification";

export default {
  components: { NavigationDrawerNotification },
  computed: {
    ...mapGetters([
      "userToken",
      "userName",
      "isMainAdmin",
      "companyName",
      "userId",
      "prfImgFile",
      "userEmail",
      "notificationList",
      "isNotificationServiceConnected",
    ]),
  },
  data: () => ({
    total_guests_in_action: 0,
    total_active_events: 0,
    total_active_properties: 0,
    property_avalable: '0 property available',
    rental_in_progress: '0 rental in progress',
    guest_in_action: '0 current rental',
    openNotificationList: false,
    active_properties_by_type: {
      HOUSE: 0,
      APARTMENT: 0,
      CAMPING: 0,
      WAREHOUSE: 0,
      GARAGE: 0,
      EVENT: 0,
    },
  }),
  created() {
    // console.log('----create dash');
    this.getDataInfo();
  },
  methods: {
    async getDataInfo() {
      const data = await PropertyDashService.getDashHeaderData();
      this.$store.dispatch("subtractLoading");

      let info = data.data;
      // console.log(info.guests_in_action[0].count);
      this.total_guests_in_action = info.guests_in_action[0].count;

      info.active_events.forEach((item) => {
        this.total_active_events += item.count;
      });

      this.getRentalInProgressPluralOrSingularName();

      info.active_properties.forEach((item) => {
        this.active_properties_by_type[item.property_type] = item.count;
        this.total_active_properties += item.count;
      });

      this.getPropertyAvailablePluralOrSingularName();
    },
    getPropertyAvailablePluralOrSingularName() {
      this.property_avalable = this.total_active_properties + ' ' + (this.total_active_properties > 1 ? 'properties available' : 'property available');
    },
    getRentalInProgressPluralOrSingularName() {
      this.rental_in_progress = this.total_active_events + ' ' + (this.total_active_events > 1 ? 'rentals in progress' : 'rental in progress');
    },
    getGuestInActionPluralOrSingularName() {
      this.guest_in_action = this.total_guests_in_action + ' ' + (this.total_guests_in_action > 1 ? 'current rentals' : 'current rental');
    },
    goToProperties() {
      router.push("/property");
    },
    goToRentals() {
      router.push("/rental");
    },
    goToGuests() {
      router.push("/home");
    }
  },
};
</script>

<style></style>