<template>
  <div class="text-xs-center signup-validation-container">
    <v-layout pa-2>
      <v-flex>
        <v-layout row class="animated fadeIn delay-0.5s mt-1">
            <v-img class="logo" contain src="@/assets/logo-salvedomi.svg" />
          </v-layout>

        <v-layout align-center justify-center row>
          <v-flex lg12 class="signup-validation-title">
            Contact us
          </v-flex>
        </v-layout>

        <v-layout align-center justify-center row>
          <v-flex lg12 class="signup-validation-subtitle">
            SalveDomi Company is here to support you every time. Contact us!
            <p></p> 
            SalveDomi - Saving your time.
          </v-flex>
        </v-layout>

        <v-layout justify-center row
          ><strong class="back-to-login-link" @click="mailToSupport"
            >developers@abundantiacr.com</strong
          ></v-layout
        >
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["companies", "tempToken"]),
  },
  methods: {
    mailToSupport() {
      window.location.href = "mailto:developers@abundantiacr.com";
    },
  },
  data() {
    return {
      validationCode: null,
    };
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/login/login.styl';
</style>

