export const SET_USER_TOKEN = (state, userToken) => {
  state.userToken = userToken;
};

export const SET_USER_NAME = (state, userName) => {
  state.userName = userName;
};

export const SET_IS_MAIN_ADMIN = (state, isMainAdmin) => {
  state.isMainAdmin = isMainAdmin;
};

export const SET_COMPANY_ID = (state, companyId) => {
  state.companyId = companyId;
};

export const SET_COMPANY_NAME = (state, companyName) => {
  state.companyName = companyName;
};

export const SET_USER_ID = (state, userId) => {
  state.userId = userId;
};

export const SET_PRF_IMG = (state, prfImg) => {
  state.prfImg = prfImg;
};

export const SET_PRF_IMG_FILE = (state, prfImgFile) => {
  state.prfImgFile = prfImgFile;
};

export const SET_USER_EMAIL = (state, userEmail) => {
  state.userEmail = userEmail;
};

export const SET_VALIDATION_EMAIL = (state, validationEmail) => {
  state.validationEmail = validationEmail;
};

export const SET_VALIDATION_USER_ID = (state, validationUserId) => {
  state.validationUserId = validationUserId;
};

export const SET_COMPANIES = (state, companies) => {
  state.companies = companies;
};

export const SET_TEMP_TOKEN = (state, tempToken) => {
  state.tempToken = tempToken;
};
