import http from "@/http";

class PropertyService {
    constructor() {
        this.baseUrl = 'platform/property'
        this.pageSize = 15
    }

    listPropertiesFromCompany(page, text_search) {
        var listCompanyUrl = `${this.baseUrl}?page=${page}&page_size=${this.pageSize}`
        if (text_search.length>0) {
            listCompanyUrl += `&text_search=${text_search}`
        }
        return http.get(listCompanyUrl)
            .then(({ data }) => {
                return data
            })
    }

    getPropertyById(id) {
        return http.get(`${this.baseUrl}\\${id}`)
            .then(({ data }) => {
                return data
            }).catch((err) => {
               throw err;
              });
    }

    deleteProperty(id) {
        return http.delete(`${this.baseUrl}/${id}`, { data: { description: "deleted" } })
            .then(({ data }) => {
                return data
            })
    }

    addProperty(property) {
        return http.post(`${this.baseUrl}`, property)
            .then(({ data }) => {
                return data
            })
    }

    editProperty(id, property) {
        return http.put(`${this.baseUrl}/${id}`, property)
            .then(({ data }) => {
                return data
            })
    }
}

export default new PropertyService()