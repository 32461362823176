<template>
  <v-form
    id="formForgotPassword"
    ref="formForgotPassword"
    v-model="valid"
    @submit="requestPassword"
  >
    <div class="text-xs-center forgot-password-container">
      <v-layout pa-2>
        <v-flex>
          <v-layout align-center justify-center row class="forgot-header mt-6">
            <v-img src="@/assets/icons/forgot-icon.png" />
          </v-layout>

          <v-layout align-center justify-center row>
            <v-flex lg12 class="forgot-title"> Forgot your password? </v-flex>
          </v-layout>

          <v-layout v-if="!mailSent" align-center justify-center row>
            <v-flex lg12 class="forgot-subtitle">
              Don't worry! Just fill in your email and we'll send you a link to
              reset your password.
            </v-flex>
          </v-layout>

          <v-layout v-if="mailSent" align-center justify-center row>
            <v-flex lg12 class="forgot-subtitle">
              Please check your email and paste your validation code below.
              <br />
              Didn't receive an email?<strong
                class="sign-up"
                @click="resendPassword"
                >Send me the code again</strong
              >
            </v-flex>
          </v-layout>

          <v-layout
            v-if="mailSent && changePasswordToken"
            align-center
            justify-center
            row
          >
            <v-flex lg12 class="px-4 forgot-subtitle">
              Please set your new password below.
            </v-flex>
          </v-layout>

          <v-layout v-if="!mailSent" align-center justify-center row>
            <v-flex lg12>
              <v-text-field
                outlined
                label="Registered email"
                color="primary"
                v-model="useremail"
                prepend-inner-icon="mail"
                :rules="[rules.required, rules.email]"
              />
            </v-flex>
          </v-layout>

          <v-layout
            v-if="mailSent && !changePasswordToken"
            align-center
            justify-center
            row
          >
            <v-flex lg12>
              <v-text-field
                outlined
                type="tel"
                label="Validation code"
                color="primary"
                maxlength="6"
                v-model="validationCode"
                prepend-inner-icon="vpn_key"
                :rules="[rules.required, rules.min]"
              />
            </v-flex>
          </v-layout>

          <v-layout
            v-if="mailSent && changePasswordToken"
            align-center
            justify-center
            row
          >
            <v-flex lg12>
              <v-text-field
                outlined
                v-model="password"
                label="Password"
                @input="validatePasswordMatch"
                color="primary"
                prepend-inner-icon="vpn_key"
                :append-icon="show ? 'visibility' : 'visibility_off'"
                @click:append="show = !show"
                :type="show ? 'text' : 'password'"
                :rules="[rules.required, rules.min, rules.matchRePassword]"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout
            v-if="mailSent && changePasswordToken"
            align-center
            justify-center
            row
          >
            <v-flex lg12>
              <v-text-field
                outlined
                v-model="repassword"
                label="Confirm password"
                color="primary"
                @input="validatePasswordMatch"
                prepend-inner-icon="vpn_key"
                :append-icon="show ? 'visibility' : 'visibility_off'"
                @click:append="show = !show"
                :type="show ? 'text' : 'password'"
                :rules="[rules.required, rules.min, rules.matchPassword]"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout justify-center row>
            <v-flex lg12>
              <v-btn
                type="submit"
                @submit="requestPassword"
                color="primary"
                :disabled="!valid"
                class="request-btn"
                >{{ mailSent ? "Validate" : "Send request" }}</v-btn
              >
            </v-flex>
          </v-layout>

          <v-layout justify-center row class="mt-4"
            ><strong class="sign-in-link" @click="goToForgotPassword"
              >Back to Sign in</strong
            ></v-layout
          >
        </v-flex>
      </v-layout>
    </div>
  </v-form>
</template>

<script>
import router from "@/router/index.js";

export default {
  methods: {
    goToForgotPassword() {
      router.push(`/login`);
    },

    validatePasswordMatch() {
      let valid = this.password === this.repassword;
      if (valid) {
        this.rules.matchPassword = true;
        this.rules.matchRePassword = true;
      } else {
        this.rules.matchPassword = "Password and confirm does not match";
        this.rules.matchRePassword = "Password and confirm does not match";
      }
    },
    async resendPassword() {
      this.mailSent = await this.$store.dispatch("forgotPassword", {
        email: this.useremail,
      });
    },
    async requestPassword(e) {
      if (!this.valid) return;
      e.preventDefault();

      if (!this.mailSent) {
        let response = await this.$store.dispatch("forgotPassword", {
          email: this.useremail,
        });
        if (response) {
          this.mailSent = true;
          this.userId = response.data.id;
        }
      }
      if (
        this.mailSent &&
        this.validationCode &&
        !this.password &&
        !this.repassword
      ) {
        let response = await this.$store.dispatch(
          "validateForgotPasswordCode",
          {
            id: this.userId,
            code: this.validationCode,
          }
        );

        if (response) {
          this.changePasswordToken = response;
        }
      }

      if (
        this.mailSent &&
        this.changePasswordToken &&
        this.password &&
        this.repassword
      ) {
        await this.$store.dispatch("changePassword", {
          password: this.password,
          token: this.changePasswordToken,
        });
        router.push(`/login`);
      }
    },
  },
  data() {
    return {
      valid: null,
      mailSent: false,
      changePasswordToken: null,
      show: false,
      useremail: null,
      password: null,
      repassword: null,
      userId: null,
      loading: false,
      validationCode: null,
      rules: {
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        required: (value) => !!value || "Required field",
        min: (v) => (v && v.length >= 6) || "Minimum 6 characters",
        matchPassword: (v) =>
          (v && v === this.password) || "Password and confirm does not match",
        matchRePassword: (v) =>
          (v && v === this.repassword) || "Password and confirm does not match",
      },
    };
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/forgot-password/forgot-password.styl';
</style>

