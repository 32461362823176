<template>
  <div>
    <v-dialog v-model="showForm" persistent scrollable max-width="1000px">
      <v-card>
        <dialog-header :title="'Manage Group'" @closed="close"></dialog-header>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-layout>
                <v-flex sm7 md7>
                  <v-text-field
                    outlined
                    label="Group Label"
                    color="primary"
                    v-model="selectedGroup.label"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="255"
                    :rules="[rules.required]"
                    counter
                  ></v-text-field>
                </v-flex>

                <v-flex sm3 md3>
                  <v-text-field
                    class="ml-1"
                    outlined
                    append-icon="groups"
                    label="Max Members"
                    color="primary"
                    v-model="selectedGroup.max_members"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="2"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>
                <v-flex sm2 md2>
                    <v-checkbox
                      class="ml-2"
                      v-model="selectedGroup.active"
                      label="Active"
                    ></v-checkbox>
                  </v-flex>

              </v-layout>
              <v-layout>
                <v-flex sm12 md12>
                  <v-textarea
                    outlined
                    counter
                    label="Description"
                    v-model="selectedGroup.description"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="500"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout v-if="selectedGroup.id">
                <v-flex sm12 md12>
                  <v-text-field
                    outlined
                    :append-outer-icon="'person_add_alt'"
                    @click:append-outer="addUser"
                    label="Add User (email)"
                    color="primary"
                    v-model="email_to_add"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="255"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout v-if="selectedGroup.id">
                <v-flex sm12 md12>
                  <v-data-table
                    :headers="groupUsersHeaders"
                    :items="users_list"
                    item-key="id"
                    :search="search"
                    hide-default-footer
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon class="mr-2" @click="removeUser(item)"
                        >mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DialogHeader from "@/components/shared/DialogHeader";
import ManagementGroupService from "@/services/managementGroupService";

export default {
  components: {
    DialogHeader
  },
  props: {
    showForm: {
      type: Boolean,
      default: false,
    },
    preSelectedGroup: {},
  },

  watch: {
    preSelectedGroup: {
      immediate: true,
      handler(newValue) {
        this.selectedGroup = newValue;
        if (this.preSelectedGroup.id) {
          this.loadGroupUsers(this.preSelectedGroup.id);
        }
      },
    },
    
  },
  data() {
    return {
      search: "",
      email_to_add: null,
      groupUsersHeaders: [
        { text: "User email", value: "user_email" },
        { text: "", align: "end", value: "actions" },
      ],
      users_list: [],
      selectedGroup: {
        id: "",
        label: "",
        max_members: 10,
      },
      valid: true,
      rules: {
        required: (value) => !!value || "Required field",
      },
      propertyRules: [
        (v) =>
          !!v ||
          "Property is required. Please click on the button below and select a property.",
      ],
    };
  },
  methods: {
    async addUser() {
      if (this.email_to_add) {
        let response = await ManagementGroupService.addUserIntoGroup(
          this.$store.dispatch,
          this.selectedGroup.id,
          this.email_to_add
        );
        
        if (response.status === 201) {
          this.email_to_add = null;
        }
        this.loadGroupUsers(this.selectedGroup.id);
      }
    },
    async removeUser(item) {
      if (item && item.id_group && item.id_user) {
        await ManagementGroupService.removeUserFromGroup(
          this.$store.dispatch,
          item.id_group,
          item.id_user
        );
        this.loadGroupUsers(this.selectedGroup.id);
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async save() {
      if (this.validate()) {
        if(this.selectedGroup.id){
          const result = await ManagementGroupService.editGroup(this.$store.dispatch, this.selectedGroup);
          this.selectedGroup = result.data;
        }else{
          const result = await ManagementGroupService.createNewGroup(this.$store.dispatch, this.selectedGroup);
          this.selectedGroup = result.data;
        }
      }
    },
    close() {
      this.resetValidation();
      this.users_list = [];
      this.search = "";
      this.selectedGroup = {};
      this.$emit("closed");
    },
    async loadGroupUsers(id) {
      let result = await ManagementGroupService.getUsersByGroupId(
        this.$store.dispatch,
        id
      );
      this.users_list = result.data;
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
