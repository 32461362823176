<!-- <template>
  <div class="text-xs-center signup-validation-container">
    <v-layout pa-2>
      <v-flex>
        <v-layout row class="animated fadeIn delay-0.5s mt-1">
            <v-img class="logo" contain src="@/assets/logo-salvedomi.svg" />
          </v-layout>

        <v-layout align-center justify-center row>
          <v-flex lg12 class="signup-validation-title">
            Links to download app from
          </v-flex>
        </v-layout>

        <v-layout justify-center row>
          <strong class="back-to-login-link" @click="playStore">
            Play Store
          </strong>

          <strong class="back-to-login-link pl-8" @click="appStore">
            App Store
          </strong>
        </v-layout>

      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["companies", "tempToken"]),
  },
  methods: {
    playStore() {
      window.location.href = "https://play.google.com/store/apps/details?id=com.salvedomi.salvedomiapp";
    },
    appStore() {
      window.location.href = "https://apps.apple.com/br/app/salvedomi/id1644879065";
    },
  },
  data() {
    return {
      validationCode: null,
    };
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/login/login.styl';
</style> -->


<script>

export default {
  created() {
    window.location.href = "https://api.salvedomi.com/.well-known/assetlinks.json";
  },
}

</script>