<template>
  <div>
    <v-row>
      <v-container style="margin-top: 20px">
        <v-form ref="form">
          <v-file-input
            ref="imageInput"
            v-model="files"
            small-chips
            multiple
            filled
            prepend-icon="mdi-camera"
            accept="image/*"
            label="Click here to select images to upload"
          ></v-file-input>
        </v-form>
      </v-container>
    </v-row>
    <v-row class="fill-height" align-content="center" justify="center">
      <v-btn
        @click="uploadImages"
        class="ma-2"
        color="primary"
        outlined
        width="200"
        :disabled="this.files.length == 0 || this.isUploading"
      >
        <v-icon style="margin-right: 5px"> mdi-cloud-upload </v-icon>Upload
        Images</v-btn
      >
    </v-row>
    <v-row v-show="isUploading">
      <v-container style="height: 100px">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col
            class="text-subtitle-1 text-center"
            style="color: grey"
            cols="12"
          >
            Uploading file {{ uploadingFileName }}...
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="blue accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </div>
</template>

<script>
import MediaService from "@/services/mediaService";

export default {
  props: {
    showUploadModal: {
      type: Boolean,
      default: false,
    },
    selectedParent: null,
    imageList: Array,
    endpoint: null,
  },
  watch: {
    showUploadModal() {
      this.files = [];
    },
  },
  computed: {
    displayImageCarousel: function () {
      return this.selectedParent.id && this.imageList.length > 0;
    },
  },
  data() {
    return {
      files: [],
      isUploading: false,
      uploadingFileName: null,
      deleteToggle: false,
      imageIndex: 0,
      reloadSlider: 0,
    };
  },
  methods: {
    uploadImages() {
      if (this.selectedParent.id) {
        if (this.files.length > 5) {
          this.$store.dispatch("openSnackbar", {
            message: "Please, selected maximum 5 images per upload. ",
          });
        } else {
          if (this.files.length > 0) {
            this.isUploading = true;
            let fileCounter = 0;
            for (let file of this.files) {
              this.uploadingFileName = file.name;
              let formData = new FormData();
              formData.append("id_" + this.endpoint, this.selectedParent.id);
              formData.append("media", file, file.name);

              MediaService.uploadMedia(this.endpoint, formData)
                .then(
                  () => {
                    //
                  },
                  (error) => {
                    this.$store.dispatch("openSnackbar", {
                      message: "Error: " + error.response.data.message,
                    });
                  }
                )
                .finally(() => {
                  fileCounter++;
                  this.uploadingFileName = file.name;
                  if (fileCounter == this.files.length) {
                    this.isUploading = false;
                    this.files = [];
                    this.$emit("allFilesUploaded");
                    console.log("Finished");
                  }
                  this.$store.dispatch("subtractLoading");
                });
            }
          } else {
            this.$store.dispatch("openSnackbar", {
              message: "Please, select one or more images to upload.",
            });
          }
        }
      } else {
        this.$emit("displaySavePropertyDialog");
      }
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
