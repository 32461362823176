import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3369B9",
        secondary: "#C4C4C4",
        anchor: "#8c9eff",
        background: "#c4c4c4",
      },
    },
  },
});

export default vuetify;
