import http from "@/http";

class DetailGroupService {
    constructor() {
        this.baseUrl = 'platform/detail-group'
        this.pageSize = 50
    }

    listDetailGroup() {
        return http.get(`${this.baseUrl}?page=1&page_size=${this.pageSize}`)
            .then(({ data }) => {
                return data
            })
    }
}

export default new DetailGroupService()