<template>
  <v-container>
    <!-- <div class="page-header">Rentals</div> -->
    <event-list></event-list>
  </v-container>
</template>

<script>
import EventList from "@/components/events/EventList.vue";
export default {
  components: { EventList },
  name: "EventsWrapper",
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
