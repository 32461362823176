import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";

const state = {
  userToken: null,
  userName: null,
  isMainAdmin: null,
  companyId: null,
  companyName: null,
  userId: null,
  validationEmail: null,
  validationUserId: null,
  companies: [],
  tempToken: null,
  prfImg: null,
  prfImgFile:null,
  userEmail:null,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
