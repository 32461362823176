<template>
  <v-card v-if="this.currentEvent.property" @click="openChat()" light max-width="500">
    <v-card-title class="primary white--text px-2">
      {{ this.currentEvent.property.free_form_address }}
    </v-card-title>
    <v-container>
      <v-row md12 class="py-2">
        <v-flex v-if="mediaLink" align-self-center md3 class="px-2">
          <v-img max-width="120px" :src="mediaLink" />
        </v-flex>
        <v-flex md9 class="font-weight-regular px-2">
          <!-- <div style="display: table-cell"> -->
          <span v-if="this.currentEvent.property.address_detail">
            <b>Apt, Suite, Number, c/o :</b>
            {{ this.currentEvent.property.address_detail }}
          </span>
          <br v-if="this.currentEvent.property.address_detail" />
          <span v-if="this.currentEvent.property.city">
            <b>City :</b>
            {{ this.currentEvent.property.city }}
          </span>
          <br v-if="this.currentEvent.property.city" />
          <span v-if="this.currentEvent.property.postal_code">
            <b>Postal code:</b>
            {{ this.currentEvent.property.postal_code }}
          </span>
          <br v-if="this.currentEvent.property.postal_code" />
          <span v-if="this.currentEvent.property.state_province">
            <b>State / Province :</b>
            {{ this.currentEvent.property.state_province }}
          </span>
          <br v-if="this.currentEvent.property.state_province" />
          <span v-if="this.currentEvent.property.country">
            <b>State / Province :</b>
            {{ this.currentEvent.property.country }}
          </span>
          <br v-if="this.currentEvent.property.country" />
          <!-- </div> -->
        </v-flex>
      </v-row>
      <v-row justify="center">
        <test-chat
          @closed="chatClosed"
          :showChat="this.showChat"
          :selectedEvent="this.currentEvent"
        ></test-chat>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import MediaService from "@/services/mediaService";
import EventService from "@/services/eventService";
import TestChat from "@/components/chat/TestChat";

export default {
  components: {
    TestChat,
  },
  props: {
    eventId: {
      type: String,
      default: null,
    },
  },
  watch: {
    eventId: {
      immediate: true,
      async handler() {
        let result = await EventService.getEventById(this.eventId);
        this.$store.dispatch("subtractLoading");
        this.currentEvent = result.data;

        if (
          this.currentEvent.property &&
          this.currentEvent.property.main_id_property_media
        ) {
          const resultMedia = await MediaService.getProtectedImageLink(
            "property",
            this.currentEvent.property.main_id_property_media,
            this.currentEvent.property.main_property_media_extension
          );
          this.$store.dispatch("subtractLoading");
          this.mediaLink = resultMedia.data;
        }
      },
    },
  },
  methods: {
     openChat() {
      this.showChat = true;
    },
    async chatClosed() {
      this.$store.dispatch('addLoading');
      await this.$store.dispatch("updateNotificationList");
      this.$store.dispatch("subtractLoading");
      this.showChat = false;
    },
    // requestChatImage() {
    //   ImageService.requestImage(`platform/event/chat/media/${this.imageName}`)
    //     .then((base64Image) => {
    //       this.image = base64Image;
    //       setTimeout(() => this.$emit("scrollDownChat"), 100);
    //     })
    //     .finally(() => {
    //       this.$store.dispatch("subtractLoading");
    //     });
    // },
  },
  data() {
    // this.requestChatImage();

    return {
      mediaLink: null,
      currentEvent: {},
      showChat: false,
    };
  },
};
</script>