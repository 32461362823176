<template>
  <div>
    <v-app>
      <TopHeader v-if="showNavigationMenu()" />
      <NavigationDrawer v-if="showNavigationMenu()" />
      <Loader />
      <snack-bar />
      <router-view></router-view>
      <v-main> </v-main>
    </v-app>
  </div>
</template>

<script>
import Loader from "./components/shared/Loader";
import SnackBar from "./components/shared/SnackBar";
import NavigationDrawer from "./components/shared/NavigationDrawer";
import TopHeader from "./components/shared/TopHeader";

export default {
  name: "App",
  components: {
    Loader,
    SnackBar,
    NavigationDrawer,
    TopHeader,
  },
  methods: {
    showNavigationMenu() {
      if (
        this.$route.name !== "login" &&
        this.$route.name !== "support" &&
        this.$route.name !== "privacy-policy" &&
        this.$route.name !== "forgotpassword" &&
        this.$route.name !== "signup" &&
        this.$route.name !== "signup-validation" &&
        this.$route.name !== "company-selection" &&
        this.$route.name !== "app-download-instructions"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  data: () => ({
    //
  }),
};
</script>

<style>
#app {
  background: url("./assets/login-background.jpg") no-repeat center center fixed !important;
  background-size: cover;
}
</style>
