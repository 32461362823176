export const SET_NOTIFICATION_LIST = (state, notificationList) => {
    state.notificationList = notificationList;
  };
  
export const SET_NOTIFICATION_SERVICE_CONNECTED = (state, isNotificationServiceConnected) => {
    state.isNotificationServiceConnected = isNotificationServiceConnected;
  };

export const SET_NOTIFICATION_SERVICE_CONNECTION = (state, notificationServiceConnection) => {
    state.notificationServiceConnection = notificationServiceConnection;
  };
