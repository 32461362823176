

<template>
  <v-dialog v-model="showChat" persistent max-width="1000px">
    <section class="chat-header">
      <v-row>
        <v-layout row class="animated fadeIn delay-0.5s mt-1">
          <v-img class="logo" contain src="@/assets/logo-salvedomi.svg" />
        </v-layout>
      </v-row>
      <v-row align="center">
        <v-col>
          <b>Main guest:</b>
          {{ this.selectedEvent.main_guest_name }}
          -
          {{ this.selectedEvent.main_guest_email }}
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col>
          <b>Property:</b>
          {{ this.selectedEvent.property_label }} -
          {{ this.selectedEvent.free_form_address }}
        </v-col>
      </v-row>
    </section>
    <v-card-text :id="this.selectedEvent.id" class="py-5 px-5 chat-area" >
    
      <div
        v-for="message in messages"
        :key="message.id"
        class="message"
        :class="{
          'message-out': message.data().userId != userId,
          'message-in': message.data().userId === userId,
        }"
      >
        <b>{{ message.data().username }}:</b>
        <br />
          <auth-image @scrollDownChat="scrollChatDown" v-if="message.data().media" :classImage="'chat-image'" :imageName="message.data().media" />
        {{ message.data().text }}
      </div>
      <div class="chat-footer" v-if=" messages && messages.length === 0">
      <v-row align="center">
        <v-col> There is no messages yet. Type something to start a new chat. </v-col>
      </v-row>
      </div>
    
    </v-card-text>
    <section class="chat-inputs">
      <v-row align="center">
        <v-col cols="10">
          <v-text-field
            ref="textToSend"
            v-model="messageToSend.message"
            label="Message"
            required
            @keyup.enter="sendMessage()"
            autofocus
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn
            color="blue darken-1"
            text
            @click="sendMessage"
            :disabled="!messageToSend.message"
          >
            Send
          </v-btn>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
      </v-card-actions>
    </section>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  query,
  collection,
  onSnapshot,
  orderBy,
  serverTimestamp,
  addDoc,
} from "firebase/firestore";
import FirestoreService from "@/services/firestoreService";
import NotificationService from "@/services/notificationService";
import AuthImage from '../shared/AuthImage.vue';

export default {
  computed: {
    ...mapGetters(["userToken", "userName"]),
  },
  components: {
    AuthImage,
  },
  props: {
    showChat: {
      type: Boolean,
      default: false,
    },
    selectedEvent: {},
  },
  watch: {
    showChat() {
      if (!this.selectedEvent.id) {
        return;
      }
      // console.log('----> CHAT:'+ this.selectedEvent.id);
      this.selectedEventId = this.selectedEvent.id;
      this.enableChat();
    },
  },
  methods: {
    close() {
      this.unsubscribe ? this.unsubscribe(): null;
      this.firestoreDb = null;
      this.messages = null; 
      this.$emit("closed");
    },
    mailToSupport() {
      window.location.href = "mailto:developers@abundantiacr.com";
    },
    scrollChatDown() {
      var element = document.getElementById(this.selectedEvent.id);
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    },
    sendMessage() {
      this.$store.dispatch("addLoading");
      // console.log(this.firestoreDb);
      if (!this.messageToSend.message) {
        this.$store.dispatch("openSnackbar", {
          message: "It's better type a message before send.",
        });
        this.$store.dispatch("subtractLoading");
        return;
      }

      const messageContent = {
        text: this.messageToSend.message,
        createdAt: serverTimestamp(), // firestore.FieldValue.serverTimestamp()
        userId: this.userId,
        username: this.userName,
      };
      var colReference = collection(
        this.firestoreDb,
        "/eventchat",
        "salvedomi",
        this.selectedEventId
      );

      addDoc(colReference, messageContent)
        .then(() => {
          NotificationService.sendChatNotification(this.$store.dispatch, this.selectedEventId, messageContent.text);
          this.clearMessageToSend();
        })
        .catch((err) => {
          console.log(err.message);
          this.$store.dispatch("openSnackbar", {
            message: err.message,
          });
        })
        .finally(() => {
          this.$store.dispatch("subtractLoading");
        });
    },
    enableChat() {
      if (!this.selectedEventId) {
        this.$store.dispatch("openSnackbar", {
          message: "Please, select an valid rental.",
        });
        return;
      }

      FirestoreService.initializeFirestoreApp()
        .then((firestoreDbCredential) => {
          this.firestoreDb = firestoreDbCredential.db;
          this.userId = firestoreDbCredential.credentials.user.uid;
          const q = query(
            collection(
              this.firestoreDb,
              "/eventchat",
              "salvedomi",
              this.selectedEventId
            ),
            orderBy("createdAt", "asc")
          );
          this.unsubscribe = onSnapshot(q, (data) => {
            this.messages = data.docs;
            setTimeout(this.scrollChatDown, 100);
          });
          NotificationService.markNotificationAsReaded(this.$store.dispatch,'CHAT', this.selectedEventId);
        })
        .finally(() => {
          this.$store.dispatch("subtractLoading");
        });
    },
    clearMessageToSend() {
      this.messageToSend = {
        message: "",
      };
    },
  },
  data() {
    return {
      images:{},
      messages: null,
      firestoreDb: this.firestoreDb,
      unsubscribe: null,
      userId: this.userId,
      messageToSend: {
        message: "",
      },
      headers: [
        { text: "Id da hora", value: "id" },
        {
          text: "Message (from chat)",
          align: "start",
          sortable: false,
          value: "data",
        },

        // { text: 'Fat (g)', value: 'fat' },
      ],
    };
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/chat/chat.styl';
</style>

