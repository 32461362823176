<template>
  <div>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Management Groups</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white primary--text" @click="showForm = true">
          <v-icon> groups </v-icon>
          <v-icon> add </v-icon>
          New Group
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <!-- <v-spacer></v-spacer> -->
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="groupHeaders"
        :items="group_list"
        item-key="id"
        :search="search"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon class="mr-2" @click="deleteItem(item)">mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <!-- Pagination area -->
    <div class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.page"
                @input="listManagementGroups()"
                class="my-4"
                :length="pagination.maxPage"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- DELETE DIALOG -->
    <v-row justify="center">
      <v-dialog
        v-model="deleteItemToggle"
        persistent
        max-width="450"
        v-if="selectedGroup"
      >
        <v-card>
          <v-card-title class="text-h5"> Delete Management Group </v-card-title>
          <v-card-text>
            Are you sure you want to delete the following group?
          </v-card-text>
            <v-card-text class="red pt-2 white--text">
            <b>All properties managed by this group may will be without managers.</b>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="(deleteItemToggle = false), resetSelectedGroup()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="
                (deleteItemToggle = false),
                  deleteItemConfirmed(selectedGroup.id)
              "
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <!-- <v-dialog v-model="showForm" persistent max-width="700px"> -->
      <management-group-form
        @closed="formClosed"
        :showForm="this.showForm"
        :preSelectedGroup="this.selectedGroup"
      ></management-group-form>
      <!-- </v-dialog> -->
    </v-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ManagementGroupForm from "@/components/management/ManagementGroupForm";
import ManagementGroupService from "@/services/managementGroupService";

export default {
  components: {
    ManagementGroupForm
  },
  created() {
    this.resetSelectedGroup();
    this.listManagementGroups();
  },
  computed: {
    ...mapGetters(["userId","companyId","isMainAdmin"]),
  },
  data() {
    return {
      search: "",
      pagination: {
        page: 1,
        maxPage: 1,
      },
      showForm: false,
      deleteItemToggle: false,
      defaultItem: {
        
        label: "",
        description: "",
        max_members: 10,
        active: true,
      },
      
      groupHeaders: [
        { text: "Label", align: "start", value: "label" },
        { text: "Max Members", value: "max_members" },
        { text: "", align:"end", value: "actions" },
      ],
      group_list: [],
    };
  },
  methods: {
    editItem(item) {
      this.showForm = true;
      this.selectedGroup = Object.assign({}, item);
    },
    formClosed() {
      this.listManagementGroups();
      this.showForm = false;
      this.resetSelectedGroup();
    },
    deleteItem(item) {
      this.deleteItemToggle = true;
      this.selectedGroup = item;
    },
    async deleteItemConfirmed(id) {
      await ManagementGroupService.deleteGroup(this.$store.dispatch, id);
      this.resetSelectedGroup();
      this.listManagementGroups();
    },
    resetSelectedGroup() {
      this.selectedGroup = Object.assign({}, this.defaultItem);
    },
    async listManagementGroups() {
      let result = await ManagementGroupService.listGroupsFromCompany(this.$store.dispatch, this.companyId);
      this.group_list = result.data;
      this.pagination.maxPage = result.max_page;
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
