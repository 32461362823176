import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import { VueMaskDirective } from "v-mask";
import VCalendar from 'v-calendar';

Vue.directive("mask", VueMaskDirective);
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
