<template>
  <div>
    <v-dialog v-model="showForm" persistent scrollable max-width="900px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <dialog-header
            :title="'Manage Detail'"
            @closed="close"
          ></dialog-header>
          <v-card-text>
            <v-layout>
              <v-container>
                <span class="category-header">Detail</span>
                <v-divider class="pa-2 mb-2"></v-divider>
                <v-layout>
                  <v-flex sm12 md6>
                    <v-text-field
                      v-if="detailGroup"
                      class="pr-2"
                      outlined
                      prepend-inner-icon="mdi-format-list-group"
                      label="Detail Group"
                      readonly
                      v-model="detailGroup.label"
                      color="primary"
                      maxlength="255"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      class="pr-2"
                      outlined
                      prepend-inner-icon="mdi-ab-testing"
                      label="Label"
                      v-model="detail.label"
                      color="primary"
                      maxlength="255"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex sm12 md10> </v-flex>
                  <v-flex sm12 md2>
                    <v-text-field
                      v-if="detail"
                      class="pr-2"
                      outlined
                      prepend-inner-icon="mdi-format-list-group"
                      label="Order in Group"
                      v-model="detail.order"
                      type="number"
                      color="primary"
                      maxlength="255"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout v-if="this.isConnectivity">
                  <v-flex sm12 md6>
                    <v-text-field
                      class="pr-2"
                      outlined
                      prepend-inner-icon="mdi-wifi"
                      label="Network Name"
                      v-model="detail.connectivity_network"
                      color="primary"
                      maxlength="255"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      class="pr-2"
                      outlined
                      prepend-inner-icon="mdi-form-textbox-password"
                      label="Network Password"
                      v-model="detail.connectivity_password"
                      color="primary"
                      maxlength="255"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout v-if="this.isConnectivity">
                  <v-flex sm12 md12>
                    <v-checkbox
                      v-model="detail.connectivity_password_visible"
                      label="Allow guest to see this password"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout v-if="!this.isConnectivity">
                  <v-flex md12>
                    <!-- <v-text-field
                    class="pr-2"
                    outlined
                    prepend-inner-icon="mdi-text-box-outline"
                    label="Description"
                    v-model="detail.description"
                    color="primary"
                    maxlength="255"
                  ></v-text-field> -->

                    <v-textarea
                      class="pr-2"
                      outlined
                      counter
                      label="Description"
                      v-model="detail.description"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
                <!-- <v-flex md12>
                  <div class="text-center">
                    <v-btn
                      @click="$refs.fileUpload.click()"
                      class="ma-2"
                      color="primary"
                      outlined
                      width="200"
                    >
                      <v-icon> videocam </v-icon>Add video</v-btn
                    >
                  </div>
                </v-flex> -->

                <v-flex md12>
                  <v-container>
                    <span>Detail Images</span>
                    <v-divider class="pa-2 mb-2"></v-divider>
                    <v-row>
                      <v-container>
                        <image-gallery
                          :key="this.reloadGallery"
                          :title="'Images'"
                          :endpoint="'detail'"
                          :selectedParent="this.detail"
                          @saveParent="save"
                        ></image-gallery>
                      </v-container>
                    </v-row>
                  </v-container>
                </v-flex>

                <v-flex class="mt-5" md12>
                  <v-container>
                    <span>Detail Videos</span>
                    <v-divider class="pa-2 mb-2"></v-divider>
                    <v-row>
                      <v-container>
                        <video-gallery
                          :title="'Videos'"
                          :endpoint="'detail'"
                          :selectedParent="this.detail"
                          v-if="showForm"
                          @saveParent="save"
                        ></video-gallery>
                      </v-container>
                    </v-row>
                  </v-container>
                </v-flex>

                <v-flex class="mt-5" md12>
                  <v-container>
                    <span>Detail Address</span>
                    <v-divider class="pa-2"></v-divider>
                    <v-switch
                      prepend-icon="pin_drop"
                      label="Same property address"
                      color="primary"
                      center
                      v-model="detail.same_property_address"
                      hide-details
                    >
                    </v-switch>

                    <v-card-text
                      v-if="!detail.same_property_address"
                      style="font-size: 12px"
                    >
                      <address-property-form
                        :selectedModelToAddress="this.detail"
                      />
                    </v-card-text>
                  </v-container>
                </v-flex>
              </v-container>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
            <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import DialogHeader from "@/components/shared/DialogHeader";
import DetailService from "@/services/detailService";
import ImageGallery from "@/components/media/ImageGallery";
import VideoGallery from "@/components/media/VideoGallery";
import AddressPropertyForm from "@/components/property/AddressPropertyForm.vue";
import PropertyService from "@/services/propertyService";

export default {
  components: {
    DialogHeader,
    ImageGallery,
    VideoGallery,
    AddressPropertyForm,
  },
  props: {
    idProperty: null,
    showForm: {
      type: Boolean,
      default: false,
    },
    detailGroup: {},
    detail: null,
  },
  watch: {
    showForm() {
      this.reloadGallery += 1;
      this.isConnectivity =
        this.detailGroup.label == "Connectivity" ? true : false;
    },
    detail: {
      immediate: true,
      handler(newValue) {
        // console.log("DETAIL");
        // console.log(newValue);
        newValue;
      },
    },
  },
  computed: {
    displayImageCarousel: function () {
      return 1;
      // FIX
      // return this.selectedProperty.id && this.images.length > 0;
    },
  },
  data() {
    return {
      reloadGallery: 0,
      reloadSlider: 0,
      valid: true,
      isConnectivity: false,
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async save() {
      this.validate();

      if (this.detail.same_property_address) {
        try {
          let result = await PropertyService.getPropertyById(this.idProperty);
          let property = result.data;

          this.detail.free_form_address = property.free_form_address;
          this.detail.address_detail = property.address_detail;
          this.detail.city = property.city;
          this.detail.state_province = property.state_province;
          this.detail.country = property.country;
          this.detail.country_code_iso3 = property.country_code_iso3;
          this.detail.free_form_address = property.free_form_address;
          this.detail.latitude = property.latitude;
          this.detail.longitude = property.longitude;
        } catch (error) {
          let msg =
            error.response.status == 422
              ? 'Field "' +
                error.response.data.detail[0].loc[1] +
                '" ' +
                error.response.data.detail[0].msg
              : error.response.data.message;
          this.$store.dispatch("openSnackbar", {
            message: "Error: " + msg,
          });
        } finally {
          this.$store.dispatch("subtractLoading");
        }
      }

      if (this.detail.id) {
        //EDIT
        DetailService.editDetail(this.detail.id, this.detail)
          .then(
            () => {
              this.$emit("saved");
            },
            (error) => {
              this.$store.dispatch("openSnackbar", {
                message: "Error: " + error.response.data.message,
              });
            }
          )
          .finally(() => {
            this.$store.dispatch("subtractLoading");
          });
      } else {
        //SAVE
        this.detail.id_detail_group = this.detailGroup.id;
        this.detail.id_property = this.idProperty;
        if (this.isConnectivity) {
          this.detail.description =
            "Tap to connect if you are in this network area.";
        }
        DetailService.addDetail(this.detail)
          .then(
            () => {
              this.$emit("saved");
            },
            (error) => {
              this.$store.dispatch("openSnackbar", {
                message: "Error: " + error.response.data.message,
              });
            }
          )
          .finally(() => {
            this.$store.dispatch("subtractLoading");
          });
      }
      this.$emit("saved");
    },
    close() {
      this.resetValidation();
      this.reloadGallery += 1;
      this.$emit("closed");
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
