<template>
  <v-form id="formLogin" v-model="valid" @submit="login">
    <div class="text-xs-center login-container">
      <v-layout class="pr-2 pl-2">
        <v-flex>
          <v-layout row class="animated fadeIn delay-0.5s mt-1">
            <v-img class="logo" contain src="@/assets/logo-salvedomi.svg" />
          </v-layout>

          <v-layout align-center justify-center row>
            <v-flex lg12 class="animated fadeInLeft">
              <v-text-field
                outlined
                label="Email"
                color="primary"
                v-model="username"
                prepend-inner-icon="mail"
                :rules="[rules.required, rules.email]"
              />
            </v-flex>
          </v-layout>

          <v-layout align-center justify-center row>
            <v-flex lg12 class="animated fadeInRight">
              <v-text-field
                outlined
                v-model="password"
                label="Password"
                color="primary"
                prepend-inner-icon="vpn_key"
                :append-icon="show ? 'visibility' : 'visibility_off'"
                @click:append="show = !show"
                :type="show ? 'text' : 'password'"
                :rules="[rules.required, rules.min]"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout justify-center row>
            <v-flex lg12>
              <v-btn
                type="submit"
                @submit="login"
                color="primary"
                :disabled="!valid"
                class="animated fadeIn delay-0.5s sign-in"
                >Sign in</v-btn
              >
            </v-flex>
          </v-layout>
          <v-layout justify-center row class="forgot-password"
            ><span @click="goToForgotPassword">forgot password?</span></v-layout
          >
          <v-layout justify-center row class="mt-3"
            >Not a member yet?<strong class="sign-up" @click="goToSignUp"
              >Sign up</strong
            ></v-layout
          >
        </v-flex>
      </v-layout>
    </div>
  </v-form>
</template>

<script>
import router from "@/router/index.js";

export default {
  methods: {
    goToForgotPassword() {
      router.push(`/forgot-password`);
    },
    goToSignUp() {
      router.push(`/signup`);
    },
    login(e) {
      if (!this.valid) return;
      e.preventDefault();
      this.$store.dispatch("login", {
        email: this.username,
        password: this.password,
        device_id: "fixed_web",
        device_os: "fixed_web_os",
        device_os_version: "fixed_web_os_v",
        device_brand: "fixed_web",
        device_model: "fixed_web",
        device_cloudmsg_id: "fixed_web"
      });
    },
  },
  data() {
    return {
      valid: null,
      show: false,
      username: null,
      password: "",
      loading: false,
      rules: {
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        required: (value) => !!value || "Required field",
        min: (v) => (v && v.length >= 4) || "Minimum 4 characters",
      },
    };
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/login/login.styl';
</style>

