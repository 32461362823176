<template>
  <v-form id="formLogin" v-model="valid">
    <div class="text-xs-center signup-container">
      <div row class="signup-header-container">CREATE ACCOUNT</div>
      <div class="pa-4">
        <v-layout row justify-center>
          <v-avatar color="primary" size="128">
            <v-icon v-if="!displayPicture" dark size="128">
              mdi-account-circle
            </v-icon>
            <img v-else :src="displayPicture" alt="Avatar" />
          </v-avatar>
          <input 
            @change="onFileSelected" 
            type="file" 
            style="display: none" 
            accept="image/*" 
            ref="fileUpload" 
            />
        </v-layout>
        <v-layout justify-center row>
          <v-flex xs10 lg3 md4 class="px-4">
            <v-btn 
              @click="$refs.fileUpload.click()" 
              color="primary" 
              outlined 
              class="upload-btn">Upload photo
            </v-btn>
          </v-flex>
        </v-layout>

        <span class="category-header">Personal details</span>
        <v-divider class="pa-2 mb-2"></v-divider>

        <v-layout>
          <v-flex lg6>
            <v-text-field 
              class="pr-2" 
              outlined 
              label="Full name" 
              color="primary" 
              v-model="register.name"
              prepend-inner-icon="person" 
              :rules="[rules.required]" 
              />
          </v-flex>
          <v-flex lg6>
            <v-text-field 
              class="pl-2" 
              outlined 
              label="Email" 
              color="primary" 
              v-model="register.email"
              prepend-inner-icon="mail" 
              :rules="[rules.required, rules.email]" 
              />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex lg3>
            <v-menu 
              ref="menu" 
              v-model="menu" 
              :close-on-content-click="false" 
              transition="scale-transition" 
              offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field 
                  outlined 
                  v-model="register.date_of_birth" 
                  label="Date of birth"
                  prepend-inner-icon="mdi-calendar" 
                  readonly 
                  v-bind="attrs" 
                  v-on="on">
                </v-text-field>
              </template>
              <v-date-picker 
                v-model="register.date_of_birth" 
                :active-picker.sync="activePicker" 
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                " 
                min="1950-01-01" 
                @change="saveBirthDate">
              </v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex lg3>
            <v-autocomplete
              class="pl-4 pr-4"
              v-model="countrySelect" 
              :items="countries" 
              hide-no-data 
              hide-selected 
              item-text="name"
              label="Country code" 
              clearable
              outlined
              prepend-inner-icon="phone"
              :rules="[rules.required]" 
              return-object>
              <template v-slot:selection="{item}">
                {{ getCountryCode(item) }}
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex lg3>
            <v-text-field 
              class="pr-4" 
              outlined label="Phone number" 
              color="primary" 
              type="tel"
              v-mask="'###############'" 
              v-model="register.mobile_number" 
              prepend-inner-icon="phone"
              :rules="[rules.required]" />
          </v-flex>

          <v-flex lg3>
            <v-combobox
              v-model="register.gender" 
              :items="gender" 
              hide-no-data 
              hide-selected 
              item-text="name"
              label="Gender"
              clearable
              outlined
              prepend-inner-icon="person"
              :rules="[rules.required]" 
              return-object>
            </v-combobox>
          </v-flex>
        </v-layout>

        <span class="category-header">Address</span>
        <v-divider class="pa-2 mb-2"></v-divider>

        <v-layout>
          <v-flex lg6>
            <v-text-field 
              class="pr-2" 
              outlined label="Address" 
              color="primary" 
              v-model="register.address"
              prepend-inner-icon="mdi-map-marker-radius"
            />
            <!-- <v-autocomplete 
              v-model="model" 
              :items="entries" 
              :loading="isLoading" 
              :search-input.sync="search" 
              hide-no-data
              hide-selected 
              item-text="address.freeformAddress" 
              item-value="API" 
              label="Address"
              prepend-inner-icon="mdi-map-marker-radius" 
              outlined 
              return-object>
            </v-autocomplete> -->
          </v-flex>
          <v-flex lg6>
            <v-text-field 
              class="pl-2" 
              outlined 
              label="Apt, suite, company, c/o" 
              color="primary"
              v-model="register.address_complement" 
              prepend-inner-icon="mdi-store-marker" 
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex lg4>
            <v-text-field 
              class="pr-2" 
              outlined 
              label="ZIP/postal code" 
              color="primary" 
              v-model="register.zipcode"
              prepend-inner-icon="mdi-crosshairs-gps"
            />
          </v-flex>
          <v-flex lg5>
            <v-text-field 
              class="pr-2 pl-2" 
              outlined 
              label="City" 
              color="primary" 
              v-model="register.city"
              prepend-inner-icon="mdi-earth" 
            />
          </v-flex>
          <v-flex lg3>
            <v-text-field 
              class="pl-2" 
              outlined 
              label="State / province" 
              color="primary" 
              v-model="register.state"
              prepend-inner-icon="mdi-earth" 
            />
          </v-flex>
        </v-layout>

        <span class="category-header">Security</span>
        <v-divider class="pa-2 mb-2"></v-divider>

        <v-layout>
          <v-flex lg4>
            <v-text-field 
              outlined 
              class="pr-2" 
              label="Password" 
              color="primary" 
              v-model="register.password"
              @input="validatePasswordMatch" 
              prepend-inner-icon="vpn_key"
              :append-icon="show ? 'visibility' : 'visibility_off'" 
              @click:append="show = !show"
              :type="show ? 'text' : 'password'" 
              :rules="[rules.required, rules.min, rules.matchRePassword]" 
            />
          </v-flex>
          <v-flex lg4>
            <v-text-field 
              class="pl-2 mr-2" 
              outlined 
              label="Confirm password" 
              @input="validatePasswordMatch"
              color="primary" 
              v-model="repassword" 
              prepend-inner-icon="vpn_key"
              :append-icon="show ? 'visibility' : 'visibility_off'" 
              @click:append="show = !show"
              :type="show ? 'text' : 'password'" 
              :rules="[rules.required, rules.min, rules.matchPassword]" 
            />
          </v-flex>
          <v-flex lg4>
            <v-layout justify-end>
              <v-checkbox 
                v-model="register.agreement_accepted" 
                lalbe="">
              </v-checkbox>
              <span class="pt-4 mt-1">I agree to Salvedomi
                <strong class="hyperlink">Terms</strong>
              </span>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout justify-center row>
          <v-flex xs10 lg3 md4 class="px-4">
            <v-btn 
              color="primary" 
              class="upload-btn" 
              @click="singup" 
              :disabled="!valid || !register.gender || !register.agreement_accepted
              ">Register
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </v-form>
</template>

<script>
import router from "@/router/index.js";
import { countries } from 'country-list-json';

export default {
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    search(val) {
      console.log(val);
      console.log(this.isLoading);

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      if (val.length > 0) {

        var apiKey = "AIzaSyCVubNG7qZPsNU5YKNuALWF7dE5CUfDvC8";
        var lang = 'en';

        fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${val}&language=${lang}&key=${apiKey}`,
          {
            method: 'GET',
            mode: 'no-cors',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': 'http://localhost:8080'
            }
          }
        )
          .then((res) => res.json())
          .then((res) => {
            console.log("results", res);
            const { results } = res;
            this.entries = results;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            (this.isLoading = false)
          });
      }
    },
  },

  methods: {
    saveBirthDate(date) {
      this.$refs.menu.save(date);
    },
    validatePasswordMatch() {
      let valid = this.register.password === this.repassword;
      if (valid) {
        this.rules.matchPassword = true;
        this.rules.matchRePassword = true;
      } else {
        this.rules.matchPassword = "Password and confirm does not match";
        this.rules.matchRePassword = "Password and confirm does not match";
      }
    },
    onFileSelected(event) {
      this.register.self_image = event.target.files[0];

      this.$emit("input", this.register.self_image);

      let reader = new FileReader();

      reader.readAsDataURL(this.register.self_image);

      reader.onload = (e) => {
        this.displayPicture = e.target.result;
      };
    },
    goToForgotPassword() {
      router.push(`/forgot-password`);
    },
    getCountryCode(item){
      return `${item.dial_code}`;
    },
    async singup() {
      if (!this.valid) return;

      let response = await this.$store.dispatch("signUp", {
        name: this.register.name,
        date_of_birth: this.register.date_of_birth,
        password: this.register.password,
        email: this.register.email,
        mobile_number: this.register.mobile_number,
        mobile_country_code: this.countrySelect.dial_code,
        mobile_country_iso: this.countrySelect.code,
        gender: this.register.gender.toUpperCase(),
        agreement_accepted: this.register.agreement_accepted,
        self_image: this.register.self_image,
      });

      if (response) {
        router.push(`/signup/validation`);
      }
    },
  },
  data() {
    return {
      activePicker: null,
      menu: false,
      gender: ['Male','Female','Other'],
      valid: null,
      register: {
        name: null,
        self_image: null,
        date_of_birth: null,
        password: null,
        email: null,
        mobile_number: null,
        gender: null,
        agreement_accepted: false,
        state: null,
        address: null,
        city: null,
        address_complement: null,
        zipcode: null,
      },
      displayPicture: null,
      show: false,
      repassword: null,
      loading: false,
      rules: {
        required: (value) => !!value || "Required field",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        min: (v) => (v && v.length >= 6) || "Minimum 6 characters",
        matchPassword: (v) =>
          (v && v === this.register.password) ||
          "Password and confirm does not match",
        matchRePassword: (v) =>
          (v && v === this.repassword) || "Password and confirm does not match",
      },
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      countrySelect: null,
      countries: countries,
    };
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/signup/signup.styl';
</style>

