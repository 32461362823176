import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";

const state = {
  isNotificationServiceConnected: false,
  notificationServiceConnection: null,
  notificationList:[
    ],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
