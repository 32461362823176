import http from "@/http";

class NotificationService {
    constructor() {
        this.baseUrl = 'platform'
    }

    listUnreadNotificationByLoggedUser(dispatch) {
        
        try {
            return http.get(`${this.baseUrl}/notification/unread`)
                .then(({ data }) => {
                    return data
                })
        } catch (error) {
            let msg =
                error.response.status == 422
                    ? 'Field "' +
                    error.response.data.detail[0].loc[1] +
                    '" ' +
                    error.response.data.detail[0].msg
                    : error.response.data.message;
            dispatch("openSnackbar", {
                message: "Error: " + msg,
            });
            return {"data": []};
        } 
    }

    markNotificationAsReaded(dispatch, type, eventId) {
        try {
            return http.put(`${this.baseUrl}/notification/read/${type}/${eventId}`)
                .then(({ data }) => {
                    return data
                })
        } catch (error) {
            let msg =
                error.response.status == 422
                    ? 'Field "' +
                    error.response.data.detail[0].loc[1] +
                    '" ' +
                    error.response.data.detail[0].msg
                    : error.response.data.message;
            dispatch("openSnackbar", {
                message: "Error: " + msg,
            });
            return {"data": []};
        }
    }

    sendChatNotification(dispatch, eventId, message) {
        try {
            return http.post(`${this.baseUrl}/event/communication`,{"event_id": eventId, "message": message})
                .then(({ data }) => {
                    return data
                })
        } catch (error) {
            let msg =
                error.response.status == 422
                    ? 'Field "' +
                    error.response.data.detail[0].loc[1] +
                    '" ' +
                    error.response.data.detail[0].msg
                    : error.response.data.message;
            dispatch("openSnackbar", {
                message: "Error: " + msg,
            });
            return {"data": []};
        }
    }

}

export default new NotificationService()