import http from "@/http";

class ImageService {
    constructor() {
        this.baseAuthUserProfileUrl = 'auth/user/profile';
    }

    requestUserProfileImage(userId, prfImg) {
        return http.get(`${this.baseAuthUserProfileUrl}/${userId}.${prfImg}`, {
            responseType: 'arraybuffer'
          })
            .then(({ data }) => {
                    var base64EncodedImage = Buffer.from(data, 'binary').toString('base64');
                    return `data:image/${prfImg};base64,${base64EncodedImage}`
                });
    }

    async requestImage(imagePath) {
        let {data} = await http.get(`${imagePath}`, {
            responseType: 'arraybuffer'
          });
          var base64EncodedImage = Buffer.from(data, 'binary').toString('base64');
          return `data:image/*;base64,${base64EncodedImage}`
    }

    
}

export default new ImageService()