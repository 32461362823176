<template>
  <div>
  <!-- <v-toolbar>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-spacer></v-spacer> 
    <v-btn icon>
      <v-icon>notifications</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>chat</v-icon>
    </v-btn>
  </v-toolbar> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>