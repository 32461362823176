<template>
  <div class="text-xs-center signup-validation-container">
    <v-layout pa-2>
      <v-flex>
        <v-layout justify-center row class="signup-validation-header mt-6">
          <v-img contain src="@/assets/icons/company-icon.svg" />
        </v-layout>

        <v-layout align-center justify-center row>
          <v-flex lg12 class="signup-validation-title">
            Select your company
          </v-flex>
        </v-layout>

        <v-layout align-center justify-center row>
          <v-flex lg12 class="signup-validation-subtitle">
            You have more than one company registered on your account, please
            choose one to proceed
          </v-flex>
        </v-layout>

        <v-layout
          v-for="company in companies"
          :key="company.id"
          align-center
          justify-center
          row
        >
          <v-flex
            lg12
            class="company-container"
            @click="selectCompany(company.id)"
          >
            {{ company.name }}
          </v-flex>
        </v-layout>

        <v-layout justify-center row
          ><strong class="back-to-login-link" @click="goToLogin"
            >Back to Login</strong
          ></v-layout
        >
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import router from "@/router/index.js";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["companies", "tempToken"]),
  },
  methods: {
    goToLogin() {
      router.push(`/login`);
    },
    selectCompany(companyId) {
      this.$store.dispatch("companyLogin", {
        id: companyId,
        token: this.tempToken,
      });
    },
  },
  data() {
    return {
      validationCode: null,
    };
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/login/login.styl';
</style>

