<template>
  <div>
    <span>Address / Location</span>
    <v-divider class="pa-2 mb-2"></v-divider>
    <address-map
      @processAddressDataOnModel="watchAddress"
      :selectedModelToAddress="this.selectedModelToAddress"
    ></address-map>

    <v-layout
    v-if="
        this.selectedModelToAddress.latitude &&
        this.selectedModelToAddress.longitude
      ">
       <v-flex md12>
        <v-text-field
          outlined
          prepend-inner-icon="mdi-map-marker-radius"
          label="Address"
          color="primary"
          v-model="selectedModelToAddress.free_form_address"
          :rules="[rules.required]"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxlength="255"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="
        this.selectedModelToAddress.latitude &&
        this.selectedModelToAddress.longitude
      "
    >
      <v-flex md4>
        <v-text-field
          class="pr-1"
          outlined
          prepend-inner-icon="mdi-store-marker"
          label="Apt, suite, company, c/o"
          color="primary"
          v-model="selectedModelToAddress.address_detail"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxlength="255"
        ></v-text-field>
      </v-flex>
      <v-flex md8>
        <v-text-field
          outlined
          prepend-inner-icon="mdi-earth"
          label="City"
          color="primary"
          v-model="selectedModelToAddress.city"
          :rules="[rules.required]"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxlength="255"
        ></v-text-field>
      </v-flex>
      
    </v-layout>
    <v-layout
      v-if="
        this.selectedModelToAddress.latitude &&
        this.selectedModelToAddress.longitude
      "
    >
      <v-flex md4>
        <v-text-field
          class="pr-1"
          outlined
          prepend-inner-icon="mdi-crosshairs-gps"
          label="ZIP/postal code"
          color="primary"
          v-model="selectedModelToAddress.postal_code"
          :rules="[rules.required]"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxlength="25"
        ></v-text-field>
      </v-flex>
      <v-flex md3>
        <v-text-field
          class="pr-1"
          outlined
          prepend-inner-icon="mdi-earth"
          label="State/province"
          color="primary"
          v-model="selectedModelToAddress.state_province"
          :rules="[rules.required]"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxlength="255"
        ></v-text-field>
        </v-flex>
        <v-flex md5>
          <v-text-field
            outlined
            prepend-inner-icon="mdi-earth"
            label="Country"
            color="primary"
            v-model="selectedModelToAddress.country"
            :rules="[rules.required]"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            maxlength="60"
          ></v-text-field>
        </v-flex>
      
    </v-layout>
  </div>
</template>
<script>
import AddressMap from "@/components/shared/AddressMap.vue";

export default {
  components: {
    AddressMap,
  },
  props: {
    selectedModelToAddress: {},
  },

  data: () => ({
    rules: {
      required: (value) => !!value || "Required field",
    },
  }),
  watch: {
    // selectedModelToAddress: {
    // //   immediate: true,
    // //   handler() {
    // //     console.log("------AD-PROOOPERTY WATCHER");
    // //   },
    // },
  },
  methods: {
    watchAddress() {
      this.$forceUpdate();
    },
  },
};
</script>