<template>
  <v-container>
    <home-header />
    <v-flex md12>
      <v-card class="mx-auto">
        <v-layout>
      <input type="hidden" id="selectedPropertyIdFromMap">
      <v-flex  class="d-flex justify-end mt-2 mr-2 ">
        <v-btn id="propertyFormBtnHome" height="35" color="white primary--text" @click="openPropertyForm">
          <v-icon> home_work </v-icon>
          <v-icon> add </v-icon>
          New Property
        </v-btn>
      </v-flex>
    </v-layout>
        <map-items-viewer
          class="pl-2 pr-2 pt-2"
          @markerSelected="processSelectedItem"
          @infoWindowSelected="processSelectedInfoWindow"
          @processAddressDataOnModel="watchAddress"
          :propertyList="this.propertyList"
          :selectedModelToAddress="this.selectedModelToAddress"
        ></map-items-viewer>
      </v-card>
    </v-flex>
    <!-- PROPERTY FORM' -->
    <v-row justify="center">
      <property-form
        @closed="propertyFormClosed"
        @saved="propertyFormSaved"
        :showForm="this.showPropertyForm"
        :selectedProperty="this.selectedProperty"
      ></property-form>
    </v-row>
  </v-container>
</template>

<script>
import PropertyDashService from "@/services/propertyDashService";
import HomeHeader from "../components/home/HomeHeader.vue";
import MapItemsViewer from "@/components/shared/MapItemsViewer.vue";
import PropertyForm from "@/components/property/PropertyForm";
import PropertyService from "@/services/propertyService";

export default {
  components: {
    MapItemsViewer,
    HomeHeader,
    PropertyForm,
  },
  name: "HomeWrapper",
  data: () => ({
    propertyList:[],
    defaultItem:{
       id: "",
        label: "",
        description: "",
        property_type: "",
        url_rental_platform: "",
    },
    showPropertyForm:false,
    selectedModelToAddress: {
      free_form_address: "United States of America",
    },
    rules: {
      required: (value) => !!value || "Required field",
    },
  }),
  created(){
    this.selectedProperty = Object.assign({}, this.defaultItem);
    this.refreshPropertiesList();
  },
  watch: {
    
  },
  methods: {
    watchAddress() {
      this.$forceUpdate();
    },
    async openPropertyForm(){
      let id = document.getElementById("selectedPropertyIdFromMap").value;
      
      if(id){
        try {
          let result = await PropertyService.getPropertyById(id);
          this.selectedProperty = Object.assign({}, result.data);
        } catch (error) {
          let msg =
            error.response.status == 422
              ? 'Field "' +
                error.response.data.detail[0].loc[1] +
                '" ' +
                error.response.data.detail[0].msg
              : error.response.data.message;
          this.$store.dispatch("openSnackbar", {
            message: "Error: " + msg,
          });
        } finally {
          this.$store.dispatch("subtractLoading");
        }
      }


      this.showPropertyForm = true;
    },
    processSelectedItem(selectedMarker){
      this.selectedAddressMarker = selectedMarker;
    },
    processSelectedInfoWindow(selectedInfoWindow){
      this.closeSelectedInfoWindow();
      this.selectedAddressInfoWindow = selectedInfoWindow;
    },
    closeSelectedInfoWindow(){
      if (this.selectedAddressInfoWindow){
        this.selectedAddressInfoWindow.close();
      }
    },
    clearSelectedMarker(){
      if(this.selectedAddressMarker){
        this.selectedAddressMarker.setMap(null);
      }
    },
    propertyFormSaved() {
      this.showPropertyForm = false;
      this.resetSelectedProperty();
      this.clearSelectedMarker();
      this.closeSelectedInfoWindow();
      this.refreshPropertiesList();
      
    },
    propertyFormClosed(mustReload = false) {
      this.showPropertyForm = false;
      this.resetSelectedProperty();

      
      if (mustReload) {
        this.refreshPropertiesList();
      }
  
    },
    async refreshPropertiesList(){
      let result = await PropertyDashService.listProperties();
      this.propertyList = result.data;
    },
    resetSelectedProperty() {
      this.selectedProperty = Object.assign({}, this.defaultItem);
      document.getElementById("selectedPropertyIdFromMap").value = null;
    },
  },
};
</script>

<style lang="stylus">
@import '~@/assets/style/shared/shared.styl';
</style>
