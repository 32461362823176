import http from "@/http";

class ManagementGroupService {
    constructor() {
        this.baseUrl = 'platform/management-group'
        this.baseUsersUrl = 'platform/management-group-users'
        this.pageSize = 100
    }

    async listGroupsFromCompany(dispatch, companyId, page = 1, text_search = '') {
        try {
            var serviceUrl = `${this.baseUrl}/${companyId}?page=${page}&page_size=${this.pageSize}`
            if (text_search.length > 0) {
                serviceUrl += `&text_search=${text_search}`
            }
            return http.get(serviceUrl)
                .then(({ data }) => {
                    return data
                })

        } catch (error) {
            let msg =
                error.response.status == 422
                    ? 'Field "' +
                    error.response.data.detail[0].loc[1] +
                    '" ' +
                    error.response.data.detail[0].msg
                    : error.response.data.message;
            dispatch("openSnackbar", {
                message: "Error: " + msg,
            });
            return { "data": [] };
        }
    }

    async getGroupById(dispatch, id) {
        try {
            return http.get(`${this.baseUrl}/${id}`)
                .then(({ data }) => {
                    return data
                }).catch((err) => {
                    throw err;
                });

        } catch (error) {
            let msg =
                error.response.status == 422
                    ? 'Field "' +
                    error.response.data.detail[0].loc[1] +
                    '" ' +
                    error.response.data.detail[0].msg
                    : error.response.data.message;
            dispatch("openSnackbar", {
                message: "Error: " + msg,
            });
            return { "data": {} };
        }
    }

    async getUsersByGroupId(dispatch, id) {
        try {
            return http.get(`${this.baseUsersUrl}/${id}`)
                .then(({ data }) => {
                    return data
                }).catch((err) => {
                    throw err;
                });
        } catch (error) {
            let msg =
                error.response.status == 422
                    ? 'Field "' +
                    error.response.data.detail[0].loc[1] +
                    '" ' +
                    error.response.data.detail[0].msg
                    : error.response.data.message;
            dispatch("openSnackbar", {
                message: "Error: " + msg,
            });
            return { "data": [] };
        }
    }

    async createNewGroup(dispatch, groupData) {

        const item = {
            "label": groupData.label,
            "description": groupData.description,
            "max_members": groupData.max_members,
            "active": groupData.active
        }
        return http.post(`${this.baseUrl}`, item)
            .then(({ data }) => {
                dispatch("openSnackbar", {
                    message: `Management group successfully created.`,
                    color: "success",
                });
                return data
            }).catch((error) => {

                let msg =
                    error.response.status == 422
                        ? 'Field "' +
                        error.response.data.detail[0].loc[1] +
                        '" ' +
                        error.response.data.detail[0].msg
                        : error.response.data.message;
                dispatch("openSnackbar", {
                    message: msg,
                });
                return { "data": {} };
            });
    }

    editGroup(dispatch, groupData) {
        const item = {
            "label": groupData.label,
            "description": groupData.description,
            "max_members": groupData.max_members,
            "active": groupData.active
          }
        return http.put(`${this.baseUrl}/${groupData.id}`, item)
            .then(({ data }) => {
                dispatch("openSnackbar", {
                    message: `Management group successfully updated.`,
                    color: "success",
                  });
                return data
            }).catch((error) => {
                
                let msg =
                    error.response.status == 422
                        ? 'Field "' +
                        error.response.data.detail[0].loc[1] +
                        '" ' +
                        error.response.data.detail[0].msg
                        : error.response.data.message;
                dispatch("openSnackbar", {
                    message: msg,
                });
                return { "data": {} };
            });
    }

    deleteGroup(dispatch,id) {
        return http.delete(`${this.baseUrl}/${id}`)
            .then(({ data }) => {
                dispatch("openSnackbar", {
                    message: `Management group successfully removed.`,
                    color: "success",
                  });
                return data
            }).catch((error) => {
                let msg =
                    error.response.status == 422
                        ? 'Field "' +
                        error.response.data.detail[0].loc[1] +
                        '" ' +
                        error.response.data.detail[0].msg
                        : error.response.data.message;
                dispatch("openSnackbar", {
                    message: msg,
                });
                return { "data": {} };
            });
    }

    async addUserIntoGroup(dispatch, groupId, userEmail) {

        const item = {
            "id_group": groupId,
            "user_email": userEmail,
        }
        return http.post(`${this.baseUsersUrl}`, item)
            .then((response) => {
                dispatch("openSnackbar", {
                    message: `User successfully added to the group.`,
                    color: "success",
                });
                return response
            }).catch((error) => {

                let msg =
                    error.response.status == 422
                        ? 'Field "' +
                        error.response.data.detail[0].loc[1] +
                        '" ' +
                        error.response.data.detail[0].msg
                        : error.response.data.message;
                dispatch("openSnackbar", {
                    message: msg,
                });
                return { "data": {} };
            });


    }

    async removeUserFromGroup(dispatch, groupId, userId) {
        return http.delete(`${this.baseUsersUrl}/${groupId}/${userId}`)
            .then((response) => {
                dispatch("openSnackbar", {
                    message: `User successfully removed from group.`,
                    color: "success",
                });
                return response
            }).catch((error) => {

                let msg =
                    error.response.status == 422
                        ? 'Field "' +
                        error.response.data.detail[0].loc[1] +
                        '" ' +
                        error.response.data.detail[0].msg
                        : error.response.data.message;
                dispatch("openSnackbar", {
                    message: msg,
                });
                return { "data": {} };
            });
    }


}

export default new ManagementGroupService()