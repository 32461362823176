import http from "@/http";

class VideoService {
    constructor() {
        this.baseUrl = 'video'
    }

    uploadVideo(endpoint, formData, parent_id) {
        return http.post(`${this.baseUrl}/video/${endpoint}?id_${endpoint}=${parent_id}`, formData)
            .then(({ data }) => {
                return data
            })
    }
}

export default new VideoService()